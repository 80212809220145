import React from "react";
import DigitalMarketing from "../../../assets/digitalmarketing.svg";
import ojas from "../../../assets/logo svg.svg"
import truepay from "../../../Images/truepay.png"

const DigitMarket = () => {
  return (
    <>
    <div className="w-full h-20 bg-[#002366]"></div>
      <div className="mt-20">
        <div className="flex flex-col lg:flex-row justify-evenly">
          <div className="px-4">
            <div className="text-gray-500 font-semibold text-center text-3xl">
              DIGITAL MARKETING
            </div>
            <div className="mt-14 w-full lg:w-[600px]">
              <div>
                At Aksh Technology Services, we're not just another digital
                marketing agency – we're your strategic partner in online
                success. With our innovative approach and tailored solutions, we
                help businesses of all sizes thrive in the ever-evolving digital
                landscape.From boosting your online visibility through SEO and
                PPC campaigns to engaging your audience with captivating content
                and social media strategies, we've got the tools and expertise
                to elevate your brand to new heights. Our team of digital
                marketing wizards is dedicated to driving results and delivering
                measurable ROI.
              </div>
            </div>
          </div>
          <div className="flex justify-center mt-10 lg:mt-0">
            <img className="h-72 lg:h-96" src={DigitalMarketing} alt="web images" />
          </div>
        </div>
        {/* our Services */}
        <div className="mt-10 py-10">
          <div className="text-gray-400 font-semibold text-center">
            OUR SERVICES
          </div>
          <div className="mt-10">
            <div className="flex flex-wrap justify-evenly gap-4">
              <div className="h-auto py-4 w-full md:w-[45%] hover:shadow-all flex items-center rounded-lg cursor-pointer hover:text-white hover:bg-blue-500 transition-all duration-700">
                <div className="h-16 w-16 p-4 ml-10 rounded-full bg-blue-500 text-white font-extrabold text-2xl flex items-center justify-center">
                  01
                </div>
                <div className="ml-4">
                  <div className="font-bold text-xl">Maintain the links</div>
                  <div className="">
                    <p>
                      We're equipped to monitor your website's incoming links
                      for potential
                    </p>
                    <p>
                      risks, ensuring your online presence remains secure and
                      optimized.
                    </p>
                    {/* <p>200 mobile applications spanning various industries.</p> */}
                  </div>
                </div>
              </div>
              <div className="h-auto py-4 w-full md:w-[45%] hover:shadow-all flex items-center rounded-lg cursor-pointer hover:text-white hover:bg-blue-500 transition-all duration-700">
                <div className="h-16 w-16 p-4 ml-10 rounded-full bg-blue-500 text-white font-extrabold text-2xl flex items-center justify-center">
                  02
                </div>
                <div className="ml-4">
                  <div className="font-bold text-xl">
                    Help in acquiring the links
                  </div>
                  <div className="">
                    <p>
                      In the realm of search engine algorithms, the significance
                      of links
                    </p>
                    <p>
                      cannot be overstated. They serve as pivotal markers that
                      enhance
                    </p>
                    <p>
                      {" "}
                      your visibility in organic search results, elevating your
                      standing in
                    </p>
                    <p>the competitive landscape of SERPs.</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-wrap justify-evenly gap-4 mt-10">
              <div className="h-auto py-4 w-full md:w-[45%] hover:shadow-all flex items-center rounded-lg cursor-pointer hover:text-white hover:bg-blue-500 transition-all duration-700">
                <div className="h-16 w-16 p-4 ml-10 rounded-full bg-blue-500 text-white font-extrabold text-2xl flex items-center justify-center">
                  03
                </div>
                <div className="ml-4">
                  <div className="font-bold text-xl">
                    Auditing your site for SEO
                  </div>
                  <div className="">
                    <p>
                      Feel free to halt your social marketing strategy whenever
                      you deem
                    </p>
                    <p>
                      fit. No need to worry about being tied down by issues or
                      concerns
                    </p>
                    <p> promptly and effectively.</p>
                  </div>
                </div>
              </div>
              <div className="h-auto py-4 w-full md:w-[45%] hover:shadow-all flex items-center rounded-lg cursor-pointer hover:text-white hover:bg-blue-500 transition-all duration-700">
                <div className="h-16 w-16 p-4 ml-10 rounded-full bg-blue-500 text-white font-extrabold text-2xl flex items-center justify-center">
                  04
                </div>
                <div className="ml-4">
                  <div className="font-bold text-xl">Remove the penalty</div>
                  <div className="">
                    <p>
                      We ensure your website follows Google's guidelines and
                    </p>
                    <p>help remove penalties, ensuring optimal performance.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* our Solution */}
        <div className="bg-blue-500">
          <div className="text-center text-white py-10 px-4">
            <h2 className="font-bold">DIGITAL MARKETING AND SOLUTION</h2>
            <h1 className="text-2xl md:text-3xl font-bold mt-4">
              Top Digital marketing Development Company
            </h1>
            <div className="mt-4 text-lg md:text-xl">
              <p>
                Elevate your online presence with our top-tier digital marketing services. We specialize in driving targeted traffic,
              </p>
              <p>
                increasing brand visibility, and maximizing ROI through strategic campaigns tailored to your business goals.
              </p>
              <p>
                Partner with us to unlock unparalleled growth and success in the digital landscape.
              </p>
            </div>
            <div>
              <button className="px-4 py-2 mt-4 text-xl hover:bg-blue-700 bg-white text-blue-500 hover:text-white rounded-lg">
                Quote Now
              </button>
            </div>
          </div>
        </div>
        {/* WE DELIVER OUR BEST */}
        <div className="bg-custom-back mt-10 pb-12">
          <div className="text-center  py-10 px-4">
            <h2 className="font-bold">WE DELIVER OUR BEST</h2>
            <h1 className="text-2xl md:text-3xl font-bold mt-4 text-gray-600">
              Why Choose AKSH Technology For Digital Marketing Promotion?
            </h1>
            <div className="mt-4 text-lg md:text-xl">
              <p>
                Enhance your online presence with AKSH Technology's
                cutting-edge PPC advertising services. Elevate your brand
                visibility and
              </p>
              <p>
                attract a surge of online traffic. Our seasoned team of content
                creators and PPC managers is dedicated
              </p>
              <p>
                to optimizing your marketing campaigns for maximum impact. With
                our expertise and state-of-the-art
              </p>
              <p>
                tools, we ensure your ads reach the right audience at the right
                time. Experience the power of PPC
              </p>
              <p>
                marketing and propel your website to new heights of success.
              </p>
            </div>
          </div>
          {/*  */}
         <div className="mt-10 flex flex-col lg:flex-row flex-wrap justify-evenly">
            <div className="h-auto py-4 w-full lg:w-[40%] hover:shadow-all  rounded-lg bg-slate-50 shadow-all cursor-pointer hover:text-white hover:bg-blue-500 transition-all duration-700 mb-10 lg:mb-0">
              <div className="ml-10">
                <div className="font-bold text-xl">Create Ads</div>
                <div>
                  From PPC setup to ad creation, we're with you at every turn.
                </div>
              </div>
            </div>
            <div className="h-auto py-4 w-full lg:w-[40%] hover:shadow-all  rounded-lg bg-slate-50 shadow-all cursor-pointer hover:text-white hover:bg-blue-500 transition-all duration-700 mb-10 lg:mb-0">
              <div className="ml-10">
                <div className="font-bold text-xl">
                  Optimize the Landing Page
                </div>
                <p>
                  The individuals who click on your link will be directed to the
                </p>
                <p> landing page where your ad will be displayed to them.</p>
              </div>
            </div>
          </div>
          {/*  */}

          <div className="mt-10 flex flex-col lg:flex-row flex-wrap justify-evenly">
            <div className="h-auto py-4 w-full lg:w-[40%] hover:shadow-all  rounded-lg bg-slate-50 shadow-all cursor-pointer hover:text-white hover:bg-blue-500 transition-all duration-700 mb-10 lg:mb-0">
              <div className="ml-10">
                <div className="font-bold text-xl">Keyword Analysis</div>
                <p>
                Ensuring your ads are easily discoverable is paramount in our view,
                </p>
                <p>
                representing the cornerstone of effective advertising.
                </p>
              </div>
            </div>
            <div className="h-auto py-4 w-full lg:w-[40%] hover:shadow-all  rounded-lg bg-slate-50 shadow-all cursor-pointer hover:text-white hover:bg-blue-500 transition-all duration-700 mb-10 lg:mb-0">
              <div className="ml-10">
                <div className="font-bold text-xl">Manage bids</div>
                <p>In our efforts to optimize your campaign results, we emphasize</p>
                <p>the importance of bidding on the appropriate keywords, as poor</p>
                <p> bids can significantly impact outcomes.</p>
              </div>
            </div>
          </div>
        </div> 

        {/* our Client */}
        <div className="px-4">
          <div className="text-center py-10">
            <h2 className="text-gray-400 font-semibold text-center">
              OUR CLIENTS
            </h2>
            <div className="flex flex-wrap justify-evenly py-5 gap-4">
            <img
                className="m-4 h-40"
                src="https://upload.wikimedia.org/wikipedia/en/e/e9/Banasthali_Vidyapeeth_Logo.png"
                alt="Client 1"
              />
              <img
                className="m-4"
                src={ojas}
                alt="Client 2"
              />
              <img
                className=" h-10 mt-20 "
                src={truepay}
                alt="Client 3"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DigitMarket;
