import React from "react";
// import game from "../../../assets/game.svg";
// import game1 from "../../../Images/game.png";
import game2 from "../../../assets/game2.svg"
import ojas from "../../../assets/logo svg.svg"
import truepay from "../../../Images/truepay.png"
const GamDev = () => {
  return (
    <>
    <div className="w-full h-20 bg-[#002366]"></div>
      <div className="mt-20">
        <div className="flex flex-col lg:flex-row justify-evenly">
          <div className="px-4">
            <div className="text-gray-500 font-semibold text-center text-3xl">
              GAME DEVELOPMENT
            </div>
            <div className="mt-14 w-full lg:w-[600px]">
              <div>
                Welcome to Aksh Technology, your premier destination for the
                best software solutions . We pride ourselves on delivering
                innovative and customized software development services that
                cater to businesses of all sizes. At Aksh Technology, we blend
                cutting-edge technology with creative thinking to bring your
                ideas to life. Our team of expert developers and designers are
                dedicated to crafting software that not only meets your needs
                but also drives your business forward. Join us on the journey to
                digital excellence. Aksh Technology – where innovation meets
                perfection.
              </div>
            </div>
          </div>
          <div className="flex justify-center mt-10 lg:mt-0">
            <img className="h-72 lg:h-96" src={game2} alt="web images" />
          </div>
        </div>
        {/* our Services */}
        <div className="mt-10 py-10">
          <div className="text-gray-400 font-semibold text-center">
            OUR SERVICES
          </div>
          <div className="mt-10">
            <div className="flex flex-wrap justify-evenly gap-4">
              <div className="h-auto py-4 w-full md:w-[45%] hover:shadow-all flex items-center rounded-lg cursor-pointer hover:text-white hover:bg-blue-500 transition-all duration-700">
                <div className="h-16 w-16 p-4 ml-10 rounded-full bg-blue-500 text-white font-extrabold text-2xl flex items-center justify-center">
                  01
                </div>
                <div className="ml-4">
                  <div className="font-bold text-xl">
                    A seasoned squad proficient in Android development.
                  </div>
                  <div className="text-center">
                    <p>
                      Our team comprises seasoned Android app developers
                      boasting
                    </p>
                    <p>
                      {" "}
                      a track record of successfully crafting and enhancing
                    </p>
                    <p>200 mobile applications spanning various industries.</p>
                  </div>
                </div>
              </div>
              <div className="h-auto py-4 w-full md:w-[45%] hover:shadow-all flex items-center rounded-lg cursor-pointer hover:text-white hover:bg-blue-500 transition-all duration-700">
                <div className="h-16 w-16 p-4 ml-10 rounded-full bg-blue-500 text-white font-extrabold text-2xl flex items-center justify-center">
                  02
                </div>
                <div className="ml-4">
                  <div className="font-bold text-xl">
                    Complete transparency guaranteed
                  </div>
                  <div className="text-center">
                    <p>
                  
                      We uphold a commitment to full transparency with our
                      clients,
                    </p>
                    <p>ensuring they remain informed about project progress</p>
                    <p>at every stage of the development cycle.</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-wrap justify-evenly gap-4 mt-10">
              <div className="h-auto py-4 w-full md:w-[45%] hover:shadow-all flex items-center rounded-lg cursor-pointer hover:text-white hover:bg-blue-500 transition-all duration-700">
                <div className="h-16 w-16 p-4 ml-10 rounded-full bg-blue-500 text-white font-extrabold text-2xl flex items-center justify-center">
                  03
                </div>
                <div className="ml-4">
                  <div className="font-bold text-xl">
                    We provide a comprehensive six-month support
                  </div>
                  <div className="text-center">
                    <p>
                      We extend a six-month support package post-project
                      delivery,prioritizing
                    </p>
                    <p>
                    
                      a seamless experience for our clients by addressing any
                      app-related
                    </p>
                    <p> issues or concerns promptly and effectively.</p>
                  </div>
                </div>
              </div>
              <div className="h-auto py-4 w-full md:w-[45%] hover:shadow-all flex items-center rounded-lg cursor-pointer hover:text-white hover:bg-blue-500 transition-all duration-700">
                <div className="h-16 w-16 p-4 ml-10 rounded-full bg-blue-500 text-white font-extrabold text-2xl flex items-center justify-center">
                  04
                </div>
                <div className="ml-4">
                  <div className="font-bold text-xl">Technical Support</div>
                  <div className="text-center">
                    <p>
                      Our dedicated technical support team is committed to
                      addressing your
                    </p>
                    <p>
                      queries promptly and providing reliable solutions,
                      ensuring
                    </p>
                    <p>a seamless and dependable experience for you.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* our Solution */}
        <div className="bg-blue-500">
          <div className="text-center text-white py-10 px-4">
            <h2 className="font-bold">GAME DEVELOPMENT</h2>
            <h1 className="text-2xl md:text-3xl font-bold mt-4">
              Top Game Development Company
            </h1>
            <div className="mt-4 text-lg md:text-xl">
              <p>
                With expertise spanning concept enhancement, concept art,
                character design, animation, game
              </p>
              <p>
                mechanics, programming, and testing, we offer a comprehensive
                suite of services, serving as your one-
              </p>
              <p>
                stop destination for all your requirements in the realm of game
                development.
              </p>
            </div>
            <div>
              <button className="px-4 py-2 mt-4 text-xl hover:bg-blue-700 bg-white text-blue-500 hover:text-white rounded-lg">
                Quote Now
              </button>
            </div>
          </div>
        </div>
        {/* WE DELIVER OUR BEST */}
        <div className="bg-custom-back mt-10">
          <div className="text-center text-gray-400 py-10 px-4">
            <h2 className="font-bold">WE DELIVER OUR BEST</h2>
            <h1 className="text-2xl md:text-3xl font-bold mt-4">
              From start to finish, we handle it all: design, development,
              deployment, and ongoing support
            </h1>
            <div className="mt-4 text-lg md:text-xl">
              <p>
                At AKSH Technology, we provide comprehensive game services at
                competitive prices. With a seasoned team well-versed
              </p>
              <p>
                in top-tier gaming, we've mastered hyper-casual, multiplayer
                RPGs, and VR realms. Our portfolio spans
              </p>
              <p>
                mobile, PC, and web platforms, leveraging state-of-the-art tools
                such as Unity3D.
              </p>
            </div>
          </div>
        </div>

        {/* our Client */}
        <div className="px-4">
          <div className="text-center py-10">
            <h2 className="text-gray-400 font-semibold text-center">
              OUR CLIENTS
            </h2>
            <div className="flex flex-wrap justify-evenly py-5 gap-4">
            <img
                className="m-4 h-40"
                src="https://upload.wikimedia.org/wikipedia/en/e/e9/Banasthali_Vidyapeeth_Logo.png"
                alt="Client 1"
              />
              <img
                className="m-4"
                src={ojas}
                alt="Client 2"
              />
              <img
                className=" h-10 mt-20 "
                src={truepay}
                alt="Client 3"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GamDev;
