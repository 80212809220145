import React from 'react'
import Gallery from "./Gallery";
import OurClient from "./OurClient";
import Services from './Services'
import Team from "./Team";
import About from "./About";
import Body from './Body';

const Home = () => {
  return (
    <div>
         <div>
      <div className="scroll-smooth">
      <section id="home" className="">
      <Body/>
        </section>
      <section id="about" className=" ">
          <About />
        </section>
        <section id="services" className="">
          <Services />
        </section>
        <section id="gallery" className="">
          <Gallery />
        </section>
        <section id="testimonials" className=" ">
          <OurClient />
        </section>
        <section id="team" className="pb-20 ">
          <Team />  
        </section>
        </div>
      </div> 
    </div>
  )
}

export default Home