import React, { useState } from "react";
import { FaTwitter, FaFacebook, FaPhoneAlt } from "react-icons/fa";
import { BsLinkedin, BsInstagram } from "react-icons/bs";
import { IoLogoYoutube, IoLocationOutline } from "react-icons/io5";
import { MdEmail } from "react-icons/md";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios'
import { HashLink } from "react-router-hash-link";
import { Link } from "react-router-dom";
import AkshTeam from "../../Images/AkshTeam.jpg";
import PaymentCard from "../../Images/paymentCard.png";
import groupic from "../../Images/groupic.jpg"
import groupic2 from "../../Images/groupic2.jpg"
import groupic3 from "../../Images/groupic3.jpg"
import startup from "../../Images/startup.png";
import pci from "../../Images/pci.webp";

const Footer1 = () => {

  const [data , setData] = useState(
    {
        name:'',
        email:'',
        message:''
    }
  )

  const handleOnChange=(e)=>{
        const {name , value}=e.target;
        setData({...data,[name]:value});
  }
  
  const notify = () => toast("Message send successfully");

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
    const response = await axios.post(process.env.REACT_APP_EMAIL_URL, data);
      notify();
      console.log('Form submitted:', response.data);
      setData({
        name: '',
        email: '',
        message: ''
      });
    } catch (error) {
      console.error('Error submitting form:', error);
      toast.error('Error submitting form');
    }
  };
  
  console.log()
  
  return (
    <>
      <div className="bg-[#6279FE] w-full px-8 md:px- lg:px-24 py-1 lg:py-4">
        <div className="flex flex-col lg:flex-row justify-around gap-10 lg:gap-0">
          <div className="flex-1">
            <h1 className="font-bold text-2xl lg:text-3xl w-full text-white">AKSH TECHNOLOGY</h1>
            <div className="h-1 bg-blue-400 w-full lg:w-[90%] mt-4"></div>
            <div className="text-white mt-4">
              <p>Aksh Technology is your Email newsletter</p>
              <p>Webflow template we can build</p>
              <p>anything your dream.</p>
            </div>
            <div className="mt-3">
              <input
              id='name'
                type="text"
                className="rounded p-2 w-full lg:w-80 outline-none"
                placeholder="Name"
                name="name"
                value={data.name}
                onChange={handleOnChange}
              />
              <input
              id="email"
                type="email"
                className="rounded p-2 w-full lg:w-80 outline-none mt-3"
                placeholder="Email"
                name="email"
                value={data.email}
                onChange={handleOnChange}
                required
              />
              <textarea
              id="message"
                className="mt-3 rounded p-2 w-full lg:w-80 outline-none"
                placeholder="Message"
                name="message"
                value={data.message}
                onChange={handleOnChange}
              />
              <button onClick={handleSubmit} className="rounded-3xl px-8 py-3 border-2 mt-2 bg-[#6373FF] text-white hover:bg-white hover:text-black transition-all duration-500">
                SEND MESSAGE
              </button>
              <ToastContainer />
            </div>
          </div>
          <div className="flex-1 text-white">
            <div className="text-xl font-semibold">Company</div>
            <div className="mt-4">
              <div className="m-4 cursor-pointer"><HashLink to="/#about">About Us</HashLink></div>
              <div className="m-4 cursor-pointer"><HashLink to="/#services">Services</HashLink></div>
              <div className="m-4 cursor-pointer"><HashLink to="/#gallery">Gallery</HashLink></div>
              <div className="m-4 cursor-pointer"><HashLink to="/#testimonials">TESTIMONIALS</HashLink></div>
              <div className="m-4 cursor-pointer"><HashLink to="/#team">TEAM</HashLink></div>
              <div className="m-4 cursor-pointer"><HashLink to="/#contact">Contact Us</HashLink></div>
            </div>
          </div>
          <div className="flex-1 text-white">
            <div className="text-xl font-semibold">Gallery</div>
            <img className="h-20 w-36 mt-4" src={AkshTeam} alt=""/>
            <img className="h-20 w-36 mt-2" src={groupic}  alt=""/>
            <img className="h-20 w-36 mt-2" src={groupic2} alt=""/>
            <img className="h-20 w-36 mt-2" src={groupic3} alt=""/>
          </div>
          <div className="flex-1 text-white">
            <div className="flex items-center text-xl font-semibold"><IoLocationOutline className="mr-2" />Address</div>
            <div className="mt-4">
              <p className="flex items-center">T-3 & T-4, A-88, sector 4, Noida,</p>
              <p className=""> Uttar Pradesh, 201301</p>
            </div>
            <div className="mt-5">
              <div className="flex items-center mb-2"><FaPhoneAlt className="mr-2" /> Phone</div>
              <a href="tel:9625510038">9625510038</a>
            </div>
            <div className="mt-5">
              <div className="flex items-center mb-2"><MdEmail className="mr-2" /> Email</div>
              <a href="mailto:help@akshtechnology.com">help@akshtechnology.com</a>
            </div>
            <div className="mt-5">
              <div className="text-xl font-semibold mb-2">Social Media</div>
              <div className="flex gap-4">
            <FaTwitter size={30} />  
               <Link to="https://www.facebook.com/share/J9K6yugQ4AxhdyvJ/?mibextid=qi2Omg"><FaFacebook size={30} /></Link> 
               <Link to="https://www.linkedin.com/company/aksh-technology/posts/?feedView=all"><BsLinkedin size={30} /></Link> 
              <IoLogoYoutube size={30} />
              <Link to="https://www.instagram.com/aksh_technology?utm_source=qr&igsh=eWJnaWtiOTZ4c2Zj"><BsInstagram size={30} /></Link>
              </div>
            </div>
          </div>
          <div className="flex-1 text-white">
            <div className="text-xl font-semibold">Payment Method</div>
            <img className="w-60 mt-4" src={PaymentCard} alt=""/>
            <div className="flex">
            <img className="w-20 mt-4" src={"https://kickrtechnologies.com/_next/static/media/iso.7a895795.png"} alt=""/>
            <img className="w-44 mt-4" src={startup} alt=""/>
            </div>
            <img className="w-44 mt-4" src={pci} alt=""/>
          </div>
        </div>
        <hr className="text-white w-full mt-4" />
        <div className="text-white text-center ltr:sm:text-left rtl:sm:text-right  pt-0 mt-2">© {new Date().getFullYear()}. Aksh Technology All rights reserved.</div>
      </div>
    </>
  );
};

export default Footer1;
