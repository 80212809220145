import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useNavigate } from "react-router-dom";


const Team = () => {

  const navigate = useNavigate();

  const CustomData = [

    {
      Image:
       "https://media.licdn.com/dms/image/C4E03AQGNR9dmzRYJaQ/profile-displayphoto-shrink_800_800/0/1590347365598?e=1725494400&v=beta&t=GrKp8QW0SqCQ_0zKplhS5FyrMh9jHC_xgEYsNyGpSeo",
      name: "Aditya Kumar",
      position: "Director",
    }
    // {
    //   Image:
    //   "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTXN89kBN-OGT6elYn1kGlsjii6QpCWJbCV9g&s",
    // name: "Jahnvi Pandey ",
    // position: "Human Resource",
    // }
    ,
    {
      
        Image:"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTXN89kBN-OGT6elYn1kGlsjii6QpCWJbCV9g&s",
        name:"Raj Ratnam",
        position:"Human Resource",
      
    }
    ,{
      Image:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTXN89kBN-OGT6elYn1kGlsjii6QpCWJbCV9g&s",
    name: "Priyanka Chauhan",
    position: "Team Leader",
    },
    {
      Image:"https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png",
      name:"Sanjay Kumar",
      position:"Full-Stack Developer",
    }
    ,
    {
      Image:
      "https://media.licdn.com/dms/image/D4D03AQHNQk8OuEuRyQ/profile-displayphoto-shrink_800_800/0/1684680501098?e=1727913600&v=beta&t=amY6evVR0niPVNzTsHC7mv9oXpp-lIGioNR5o734OBs",
    name: "Smitha Pachauri",
    position: "React Native Developer",
    },
    {
      Image:"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTXN89kBN-OGT6elYn1kGlsjii6QpCWJbCV9g&s",
      name: "Parul Rawat",
      position: "React Js Developer",
     }
    ,
    {
      Image:
        "https://media.licdn.com/dms/image/D5603AQFizd8agGEAOQ/profile-displayphoto-shrink_800_800/0/1681713000195?e=2147483647&v=beta&t=oQWxUvy522tYeylq8Al2GrkzYnobeCf4m09Zsx0bjvs",
      name: "Ranjay Kumar",
      position: "Frontend Developer",
    },{
      Image:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQdZBr5y-yiLk1E5d1vYnLUCQcOxTtVsQCIVTk0VWN7zA&s",
      name: "Kamal Rathee",
      position: "UI/UX Designer",
    },{
      Image:
        "https://media.licdn.com/dms/image/D4E03AQFVwM-cfHqjgA/profile-displayphoto-shrink_800_800/0/1686072609042?e=2147483647&v=beta&t=ihsshzfwBITkUk7cx7E9ltRQLYOyOgf2cz4OqL_RV3I",
      name: "Shibashis Pandit",
      position: "UI/UX Designer",
    },
    {
      Image:"https://media.licdn.com/dms/image/D5603AQECloSboAgSUA/profile-displayphoto-shrink_200_200/0/1704880024093?e=1727913600&v=beta&t=G_Ra5xHOyQ2gJlI3etBewibw2q5v80zXnpZhtRn0oy4",
      name:"Nusrat Perween",
      position:"UI/UX Designer",
  },
    {
      Image:"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQEiLVo72682852cODfcYArD_8qAzpfvzz2kg&s",
      name:"Raushan Kumar",
      position:"Node Js Developer",
    }
    ,
    {
      Image:"https://media.licdn.com/dms/image/D4D03AQHdjKYr7ObQcg/profile-displayphoto-shrink_200_200/0/1702157409781?e=1727913600&v=beta&t=aFQVXguhuqQ8mrDEyXoTumjTihvadYUpoi6xG4tBtpw",
      name:"Aseer Sarwar",
      position:"Node Js Developer",
    }
    , 
    {
      Image:"https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png",
      name:"Sumit Pandey",
      position:"React Js Developer",
    },
    {
      Image:"https://media.licdn.com/dms/image/D4D03AQH1naJ5PqwkLA/profile-displayphoto-shrink_200_200/0/1688651328374?e=2147483647&v=beta&t=qLs9krRslVIVkFBcMdDMd05wrQRFP83M9K8ki5lqTNQ",
      name:"Ankit Gupta",
      position:"React Js Developer",
    },
    {
      Image:"https://media.licdn.com/dms/image/D5603AQGv0g4M9udOgg/profile-displayphoto-shrink_200_200/0/1682500948910?e=1727913600&v=beta&t=nI6PaKEPkuqEKN91RQVSdCLGH7Yo7j1ioeJqMsxGQ20",
      name:"Ujjwal Pratap Singh",
      position:"React Js Developer",
    },
    {
      Image:"https://media.licdn.com/dms/image/C4D03AQHQID1dH152oA/profile-displayphoto-shrink_200_200/0/1658301068650?e=1727913600&v=beta&t=jHgIreqsq0tkcKj77jfyz_ZchCIA4_S6WsbJCOVP9cI",
      name:"Anurag Tomar",
      position:"React Native Developer",
    },
    {
      Image:"https://media.licdn.com/dms/image/D5603AQH2hytwiHQNdA/profile-displayphoto-shrink_200_200/0/1722424267927?e=1727913600&v=beta&t=5Vrg-LDK4q5svxDNS5v2egrViFYp3eKJktMlLTxupiU",
      name:"Vidur Joshi",
      position:"React Native Developer",
    },
    {
      Image:"https://media.licdn.com/dms/image/D5603AQEJZ3lYb63TvQ/profile-displayphoto-shrink_200_200/0/1703696118314?e=2147483647&v=beta&t=3qLchIei3Adgg8ufEFKTjJXUKvaYD1z1EeDJUyAjwEA",
      name:"Sumit Bhargava",
      position:"React Native Developer",
    },
    

  
  ];

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

  const handleViewClick = (name) => {
    navigate(`/team/${name}`);
  };


  return (
    <>
    <div className="text-center py-6">
    <h1 className="font-bold text-2xl sm:text-3xl md:text-4xl">MEET THE TEAM</h1>
    <div className="h-1 bg-blue-400 w-16 mt-4 mx-auto"></div>
</div>
    <Carousel responsive={responsive}  showDots={true}  infinite={true} autoPlay={true} autoPlaySpeed={3000}  keyBoardControl={true} removeArrowOnDeviceType={['mobile', 'tablet']} >
     {CustomData.map((item)=> (
      <div className="h-80">
                 <div className="">
                  <img
                    key={item}
                    src={item.Image}
                    className= "w-44 max-w-44 rounded-full  m-auto" alt=""/>
                    </div>
                  <div className="font-semibold text-center">
                    {item.name}
                  </div>
                  <div className="text-center">
                    {item.position}
                  </div>
                  <button
                onClick={() => handleViewClick(item.name)}
                className=" text-blue-400 ml-72 hover:underline"
              >
                View
              </button>
                  </div>
                ))}
                </Carousel>
                </>
  );
};

export default Team;