import React, { useEffect, useState } from 'react';
import Banner1 from "../../Images/Banner1.png";
import Banner2 from "../../Images/Banner2.png";
import Banner3 from "../../Images/Banner3.png";

const Body = () => {
  const Images = [Banner1, Banner2, Banner3];

  const [currentImage, setCurrentImage] = useState(0);

  const incCount = () => {
    setCurrentImage((currentImage + 1) % Images.length);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      incCount();
    }, 3000);
    return () => {
      clearTimeout(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentImage]);

  return (
    <div className="flex justify-center items-center w-full h-[50vh] sm:h-[60vh] md:h-[70vh] lg:h-[80vh] xl:h-[90vh] 2xl:h-[100vh] max-w-[1700px] xl:max-w-[1920px] mx-auto">
      <div className="relative h-full w-full  lg:h-[122%] xl:h-[110%] overflow-hidden flex justify-center items-center">
        {Images.map((item, i) => (
          <img
            key={i}
            src={item}
            className={`absolute transition-opacity duration-1000 ease-in-out ${currentImage === i ? 'opacity-100' : 'opacity-0'}`}
            style={{ width: '100%', height: '100%', objectFit: 'cover' }}
            alt={`Slide ${i + 1}`}
          />
        ))}
      </div>
    </div>
  );
};

export default Body;
