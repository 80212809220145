import React from 'react'
import { FaCheck } from "react-icons/fa";
import people from "../../assets/people.svg"

const About = () => {
  return (
    <>
      <div className='flex flex-col lg:flex-row gap-10 mx-4 md:mx-10 lg:mx-20 items-center text-center py-10 md:py-20'>
        <img className='w-full md:w-1/2 lg:w-1/3 mx-auto' src={people} alt='images'/>
        <div className='mt-10 lg:mt-0 px-14'>
          <h1 className='font-bold text-4xl text-center lg:text-left'>ABOUT US</h1>
          <div className='h-1 bg-blue-400 w-40 mt-4 mx-auto lg:mx-0'></div>
          <div className='text-gray-500 mt-4 text-center lg:text-left space-y-2'>
            <p className=''>Welcome to our vibrant IT company where innovation meets excellence. At Aksh Technology, we're not just about creating software, we're crafting experiences.
            Specializing in UI/UX design, JavaScript, CSS, mobile app development, Node.js, React.js, React Native, HTML, and more, we're dedicated to bringing your digital dreams to life. Join us on this journey of innovation, where every line of code is infused with creativity and every project is a masterpiece in the making.</p>
          </div>
          <div className='font-serif text-2xl mt-6 text-center lg:text-left'>Why Choose Us?</div>
          <div className='flex flex-col md:flex-row justify-around text-gray-500 mt-4 space-y-4 md:space-y-0'>
            <div className='flex flex-col items-center md:items-start'>
              <div className='flex gap-2 items-center'><FaCheck className='text-blue-500' /><p> Expertise Meets Innovation</p></div>
              <div className='flex gap-2 items-center'><FaCheck className='text-blue-500' /><p>Affordable Solutions</p></div>
              <div className='flex gap-2 items-center'><FaCheck className='text-blue-500' /><p>Timely Delivery</p></div>
            </div>
            <div className='flex flex-col items-center md:items-start'>
              <div className='flex gap-2 items-center'><FaCheck className='text-blue-500' /><p>Client-Centric Approach</p></div>
              <div className='flex gap-2 items-center'><FaCheck className='text-blue-500' /><p>Creative Excellence</p></div>
              <div className='flex gap-2 items-center'><FaCheck className='text-blue-500' /><p>Quality Assurance</p></div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default About
