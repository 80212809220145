import React from "react";
import { useParams } from "react-router-dom";

const TeamMemberDetails = () => {
  const { name } = useParams();
  const CustomData = [
    {
      Image:
        "https://media.licdn.com/dms/image/C4E03AQGNR9dmzRYJaQ/profile-displayphoto-shrink_800_800/0/1590347365598?e=1725494400&v=beta&t=GrKp8QW0SqCQ_0zKplhS5FyrMh9jHC_xgEYsNyGpSeo",
      name: "Aditya Kumar",
      position: "Director",
      detail: `
    
      Aditya Kumar, the Director of AKSH Technology, is a visionary leader with a passion for innovation and excellence. With a background in technology and a keen understanding of market dynamics, Aditya has steered AKSH Technology towards remarkable growth and success.
      
      Under his leadership, AKSH Technology has become synonymous with cutting-edge solutions and impeccable service. Aditya's strategic acumen has enabled the company to adapt to evolving industry trends and stay ahead of the competition. His commitment to quality and customer satisfaction has earned AKSH Technology a sterling reputation in the market.`,
    },
    // {
    //   Image:
    //     "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTXN89kBN-OGT6elYn1kGlsjii6QpCWJbCV9g&s",
    //   name: "Jahnvi Pandey",
    //   position: "Human Resource",
    //   detail: `
    //   Jahnvi, the HR at AKSH Technology, is a pivotal figure in fostering a thriving and cohesive work environment. With her keen understanding of human dynamics and organizational needs, Jahnvi ensures that AKSH's workforce remains motivated, engaged, and aligned with the company's goals.
      
    //   Her strategic approach to talent management encompasses recruitment, training, and development initiatives that not only attract top talent but also nurture their potential for growth within the organization. Jahnvi's emphasis on employee well-being and work-life balance cultivates a culture of productivity and fulfillment.`,
    // },
    {
      Image:"https://media.licdn.com/dms/image/C4E03AQFykFLNoa_fWA/profile-displayphoto-shrink_200_200/0/1656405091081?e=2147483647&v=beta&t=zgRqBcuH5zinptcg6RA9J9BrHVtU_1mhwxjsiXcxSM0",
      name:"Raj Ratnam",
      position:"Human Resource",
      detail:`At Aksh Technology, we empower innovation and growth. Join our collaborative team and shape the future of IT. We offer:

- Professional development opportunities
- Competitive benefits and wellness programs
- Diverse and inclusive culture
- Continuous learning and adaptability
- Recognition and rewards for outstanding performance

Be part of our dynamic team and contribute to making a difference in the tech industry. We value our employees' experiences, perspectives, and ideas. Let's grow and innovate together!"`
    },
    {
      Image:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTXN89kBN-OGT6elYn1kGlsjii6QpCWJbCV9g&s",
      name: "Priyanka Chauhan",
      position: "Senior Developer",
      detail: `Priyanka Chauhan is a talented React Native developer with a passion for building cutting-edge mobile applications. With a keen eye for detail and a knack for problem-solving, Priyanka consistently delivers high-quality software solutions that exceed expectations. Her expertise lies in leveraging the power of React Native to create seamless user experiences across multiple platforms. Priyanka's dedication to her craft and her commitment to staying updated with the latest technologies make her a valuable asset to any development team. Outside of coding, Priyanka enjoys exploring new technologies, attending tech meetups, and sharing her knowledge with the community. With her drive and determination, Priyanka is poised to make a significant impact in the world of mobile app development.`,
    },
    {
      Image:
        "https://media.licdn.com/dms/image/C4E03AQGWX3SFUzh6ng/profile-displayphoto-shrink_400_400/0/1634791256886?e=1723075200&v=beta&t=D8DQsTndlu2LwcLQsPM56kkclMQGebpk25OgKWGeDag",
      name: "Sanjay Kumar",
      position: "Full-Stack Developer",
      detail: ` Sanjay Kumar a passionate and experienced Full-stack Web Developer with expertise in various technologies. He is specialize in crafting robust and scalable web applications using Node.js, Spring Boot, Nest.js, and React.js. With a strong background in both front-end and back-end development. He  bring a holistic approach to every project He undertakes.`,
    },
    {
      Image:
        "https://media.licdn.com/dms/image/D4D03AQHNQk8OuEuRyQ/profile-displayphoto-shrink_800_800/0/1684680501098?e=1727913600&v=beta&t=amY6evVR0niPVNzTsHC7mv9oXpp-lIGioNR5o734OBs",
      name: "Smitha Pachauri",
      position: "React Native Developer",
      detail: `Smita is a talented React Native developer with a passion for building cutting-edge mobile applications. With a keen eye for detail and a knack for problem-solving, Smita consistently delivers high-quality software solutions that exceed expectations. Her expertise lies in leveraging the power of React Native to create seamless user experiences across multiple platforms. Priyanka's dedication to her craft and her commitment to staying updated with the latest technologies make her a valuable asset to any development team. Outside of coding. impact in the world of mobile app development.`,
    },
    {
      Image:
        "https://media.licdn.com/dms/image/D5603AQFizd8agGEAOQ/profile-displayphoto-shrink_800_800/0/1681713000195?e=2147483647&v=beta&t=oQWxUvy522tYeylq8Al2GrkzYnobeCf4m09Zsx0bjvs",
      name: "Ranjay Kumar",
      position: "Frontend Developer",
      detail: `Meet Ranjay Kumar, a talented frontend developer with a passion for crafting engaging digital experiences. With a robust skill set that includes HTML, CSS, JavaScript, and React, Ranjay Kumar brings creativity and technical prowess to every project undertaken.

        Equipped with an eye for design and a deep understanding of user experience principles, Ranjay Kumar transforms static concepts into dynamic, interactive websites and web applications. Whether it's implementing responsive layouts, optimizing performance, or building seamless user interfaces.`,
    },
    {
      
        Image:"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTXN89kBN-OGT6elYn1kGlsjii6QpCWJbCV9g&s",
        name: "Parul Rawat",
        position: "React Js Developer",
        detail:`Parul Rawat is a experienced and passionate web developer. With a 2+ years of experience in frontend development,she brings value to every project and is a dedicated and hardworking individual.`
       
    },
    {
      Image:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQdZBr5y-yiLk1E5d1vYnLUCQcOxTtVsQCIVTk0VWN7zA&s",
      name: "Kamal Rathee",
      position: "UI/UX Design",
      detail: `Welcome to the portfolio of Kamal Rathee, a dedicated UI/UX designer specializing in creating user-centered and visually engaging digital experiences. With expertise in user research, wireframing, and prototyping, Kamal transforms complex concepts into intuitive and aesthetically pleasing interfaces, ensuring a seamless and delightful user journey.`,
    },
    {
      Image:
        "https://media.licdn.com/dms/image/D4E03AQFVwM-cfHqjgA/profile-displayphoto-shrink_800_800/0/1686072609042?e=2147483647&v=beta&t=ihsshzfwBITkUk7cx7E9ltRQLYOyOgf2cz4OqL_RV3I",
      name: "Shibashis Pandit",
      position: "UI/UX Designer",
      detail: `Meet Shibashis Pandit, a versatile UI/UX and Graphic Designer with a passion for creating visually stunning and user-friendly digital experiences. With an impressive skill set that includes proficiency in design tools like Adobe Creative Suite and Figma. Shibashis Pandit brings a blend of creativity and technical expertise to every project. Equipped with a keen eye for detail and a deep understanding of user experience principles, Shibashis Pandit transforms ideas into visually engaging and intuitive designs. Whether it's crafting elegant user interfaces, developing seamless user journeys, or producing compelling graphics, Shibashis Pandit ensures that every design is not only aesthetically pleasing but also functionally superior.`,
    },
    {
      Image:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQEiLVo72682852cODfcYArD_8qAzpfvzz2kg&s",
      name: "Raushan Kumar",
      position: "Node Js Developer",
      detail: `Meet Raushan Kumar , a talented Backend developer with a passion for crafting engaging digital experiences. With a robust skill set that includes HTML, CSS, JavaScript,  Nodejs,python,Mongodb. Rishabh Mishra brings creativity and technical prower to every project undertaken. Equipped with an eye for design and a deep understanding of user experience principles, Rishabh Mishra transforms static concepts into dynamic, interactive websites and web applications. Whether it's implementing responsive layouts, optimizing performance, or building seamless user interfaces.`,
    },
    
    {
        Image:"https://media.licdn.com/dms/image/D4D03AQHdjKYr7ObQcg/profile-displayphoto-shrink_200_200/0/1702157409781?e=1727913600&v=beta&t=aFQVXguhuqQ8mrDEyXoTumjTihvadYUpoi6xG4tBtpw",
        name:"Aseer Sarwar",
        position:"Node Js Developer",
        detail:` Aspiring Full Stack Developer with comprehensive training in the MERN stack (MongoDB, Express.js, ReactJS, and Node.js). Passionate about building scalable and efficient web applications, with a keen eye for innovative solutions.`
    },
    {
      Image:"https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png",
      name:"Sumit Pandey",
      position:"React Js Developer",
      detail:`As a highly skilled ReactJS Developer, I, Sumit Pandey, specialize in creating dynamic and responsive web applications. With deep expertise in React, Redux Toolkit, and JavaScript, I deliver innovative solutions to front-end development challenges, ensuring optimal user experiences and high performance. My proficiency in React enables me to build interactive, reusable components, while my advanced knowledge of JavaScript allows me to implement complex functionalities seamlessly.
      I excel in state management using Redux Toolkit, ensuring efficient and maintainable application state. Additionally, I have strong skills in CSS, HTML, Tailwind, and Bootstrap for designing visually appealing, responsive layouts. My commitment to robust testing with Jest and clean, maintainable code practices ensures consistent performance across various web browsers.`
    },
    {
      Image:"https://media.licdn.com/dms/image/D4D03AQH1naJ5PqwkLA/profile-displayphoto-shrink_200_200/0/1688651328374?e=2147483647&v=beta&t=qLs9krRslVIVkFBcMdDMd05wrQRFP83M9K8ki5lqTNQ",
      name:"Ankit Gupta",
      position:"React Js Developer",
      detail:`I am MERN stack developer specializing in backend development. My excel in building secure, efficient APIs using Express.js and MongoDB, adhering to MVC design patterns. With experience in integrating external services like OTP-based authentication,  I focus on creating robust, user-friendly applications that deliver a seamless experience. `
    },
    {
      Image:"https://media.licdn.com/dms/image/D5603AQGv0g4M9udOgg/profile-displayphoto-shrink_200_200/0/1682500948910?e=1727913600&v=beta&t=nI6PaKEPkuqEKN91RQVSdCLGH7Yo7j1ioeJqMsxGQ20",
      name:"Ujjwal Pratap Singh",
      position:"React Js Developer",
      detail:`Ujjwal Pratap Singh is a skilled developer with advanced expertise in the MERN stack, adept at leveraging MongoDB for sophisticated data management, Express.js for optimized API development, React.js for complex UI/UX with hooks and Redux, and Node.js for robust server-side solutions. He excels in Tailwind CSS, utilizing its utility-first approach to craft highly responsive and customizable designs efficiently. His proficiency includes integrating these technologies to build seamless, high-performance web applications.`
    },
    {
      Image:"https://media.licdn.com/dms/image/C4D03AQHQID1dH152oA/profile-displayphoto-shrink_200_200/0/1658301068650?e=1727913600&v=beta&t=jHgIreqsq0tkcKj77jfyz_ZchCIA4_S6WsbJCOVP9cI",
      name:"Anurag Tomar",
      position:"React Native Developer",
      detail:`Anurag Tomar is a dedicated and seasoned Software developer with proficiency in a wide range of technologies .Anurag Tomar is a dedicated and seasoned App Developer specializing in crafting robust and scalable mobile applications using React Native ,CSS, JavaScript.Anurag Tomar dedication to his craft and his commitment to staying update with the latest technologies make him a valuable to any development team. His work has made a significant impact in the world of mobile app development.`
    },
    {
        Image:"https://media.licdn.com/dms/image/D5603AQECloSboAgSUA/profile-displayphoto-shrink_200_200/0/1704880024093?e=1727913600&v=beta&t=G_Ra5xHOyQ2gJlI3etBewibw2q5v80zXnpZhtRn0oy4",
        name:"Nusrat Perween",
        position:"UI/UX Designer",
        detail:`Hello my name is Nusrat and I am a ui/ux designer.The rapidly evolving landscape of UI/UX design excites me, and I am committed to staying at the forefront of industry trends. My adaptability is evident in my ability to quickly grasp new tools and technologies, ensuring that I can contribute effectively to your projects from day one.I am excited about the opportunity to bring my skills and passion for design to your company.`
    },
    {
      Image:"https://media.licdn.com/dms/image/D5603AQH2hytwiHQNdA/profile-displayphoto-shrink_200_200/0/1722424267927?e=1727913600&v=beta&t=5Vrg-LDK4q5svxDNS5v2egrViFYp3eKJktMlLTxupiU",
      name:"Vidur Joshi",
      position:"React Native Developer",
      detail:`Skilled in building cross-platform mobile applications using React Native, leveraging JavaScript and modern technologies to deliver seamless user experiences. Proficient in creating reusable components, managing state with Redux, and integrating APIs for dynamic data handling.`
    },
    {
      Image:"https://media.licdn.com/dms/image/D5603AQEJZ3lYb63TvQ/profile-displayphoto-shrink_200_200/0/1703696118314?e=2147483647&v=beta&t=3qLchIei3Adgg8ufEFKTjJXUKvaYD1z1EeDJUyAjwEA",
      name:"Sumit Bhargava",
      position:"React Native Developer",
      detail:` As an enthusiastic React Native developer, I specialize in creating attractive and functional applications using HTML, CSS, JavaScript, React Native, and jQuery. I have a solid foundation in web technologies and am familiar with React.js,Nodejs and MongoDB. I am skilled in problem-solving and have a passion for exploring and learning new technologies.I am eager to further enhance my skills in mobile app development and contribute to exciting projects. My goal is to leverage my diverse skill set to build innovative and user-friendly applications.`
    }
  ];

  const member = CustomData.find((member) => member.name === name);

  return (
    <>
    <div className="h-20 bg-blue-400"></div>
    <div className="text-center mt-10 min-h-screen">
      <img
        src={member.Image}
        className="w-44 max-w-44 rounded-full m-auto"
        alt=""
      />
      <h1 className="font-bold text-4xl">{member.name}</h1>
      <div className="font-semibold text-center mt-4">{member.position}</div>

      <div className="text-wrap w-[70%] m-auto text-center ">
        {member.detail}
      </div>
    </div>
    </>
  );
};

export default TeamMemberDetails;
