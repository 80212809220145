import React from "react";
import image1 from "../../Images/1.jpg";
import image2 from "../../Images/2.jpg";
import image3 from "../../Images/3.jpg";
import image4 from "../../Images/4.jpg";
import image5 from "../../Images/5.jpg";
import image6 from "../../Images/6.jpg";
import image7 from "../../Images/7.jpg";
import image8 from "../../Images/8.jpg";
import image9 from "../../Images/9.jpg";
import image10 from "../../Images/groupic2.jpg";
import image11 from "../../Images/groupic3.jpg";
import AkshTeam from "../../Images/AkshTeam.jpg";
import Akshgroupimg from "../../Images/Akshgroupimg.JPG";
import image12 from "../../Images/group4.jpg"

import image14 from "../../Images/group6.jpg"

import image16 from "../../Images/group8.jpg"
import image17 from "../../Images/group9.jpg"
import image18 from "../../Images/group10.jpg"

import { FaLocationArrow } from "react-icons/fa6";
const images = [
  Akshgroupimg,
  image12,
  image14,image16,image17,image18,
  image5,
  image1,
  image6,
  image4,
  image2,
  image8,
  image7,
  image3,
  AkshTeam,
  image9,
  image10,
  image11,
];

const Gallery = () => {
  const handleClick = () => {
    window.open("/view_all_images", "_blank");
  };
  return (
    <>
      <div className="text-center py-10">
        <h1 className="font-bold text-4xl">GALLERY</h1>
        <div className="h-1 bg-blue-400 w-16 mt-4 mx-auto"></div>
        <div className="flex flex-wrap justify-center gap-6 mt-10 px-4">
          {images.slice(0, 12).map((image, index) => (
            <div
              key={index}
              className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5 p-2"
            >
              <img
                className="gallery-image w-full h-48 border"
                src={image}
                alt={`${index + 1}`}
              />
            </div>
          ))}
        </div>
        <style jsx>{`
          .gallery-image {
            transition: transform 0.3s ease;
          }
          .gallery-image:hover {
            transform: scale(1.3);
          }
        `}</style>
      </div>
      <div className="w-full md:w-1/2 lg:w-1/3 xl:w-1/4 mx-auto mb-8 bg-blue-500 relative flex items-center justify-center py-2 text-white font-semibold rounded-md">
        <div
          className="flex justify-center items-center gap-2 hover:underline cursor-pointer"
          onClick={handleClick}
        >
          <FaLocationArrow className="mt-1" />
          <span>View All Pictures</span>
        </div>
      </div>
    </>
  );
};

export default Gallery;
