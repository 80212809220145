import React from "react";
import UI from "../../../assets/ui.svg";
import UI2 from "../../../assets/ui2.svg";
import ojas from "../../../assets/logo svg.svg"
import truepay from "../../../Images/truepay.png"
const UIUXDesign = () => {
  return (
    <>
      <div className="w-full h-20 bg-[#002366]"></div>
      <div className="mt-20">
        <div className="flex flex-col lg:flex-row justify-evenly">
          <div className="px-4">
            <div className="text-gray-500 font-semibold text-center text-3xl">
              UI/UX Design
            </div>

            <div className="mt-14 w-full lg:w-[600px]">
              <div>
                Welcome to our vibrant IT company where innovation meets
                excellence. At Akshtech, we're not just about creating software;
                we're crafting experiences. Specializing in UI/UX design,
                JavaScript, CSS, mobile app development, Node.js, React.js,
                React Native, HTML, and more, we're dedicated to bringing your
                digital dreams to life. Join us on this journey of innovation,
                where every line of code is infused with creativity and every
                project is a masterpiece in the making. Let's redefine the
                digital landscape together.
              </div>
            </div>
          </div>
          <div className="flex justify-center mt-10 lg:mt-0">
            <img className="h-72 lg:h-96" src={UI2} alt="web images" />
          </div>
        </div>
        {/* mobile app developement */}
        <div className="bg-blue-500">
          <div className="text-center text-white py-10">
            <h2 className="font-bold"> UI/IX DESIGNING QUALITIES</h2>
            <h1 className="text-3xl font-bold mt-4">
              Top UI/UX Design Company
            </h1>
            <div className="mt-4 text-xl">
              <p>
                Our UI/UX design company specializes in bridging the gap between
                user expectations and your business objectives.
              </p>
              <p>
                Our developers utilize cutting-edge tools like Sketch, InVision,
                Adobe Photoshop, and Adobe
              </p>
              <p>
                Illustrator to create data-driven designs that align with
                technological advancements.
              </p>
            </div>
            <div>
              <button className="px-4 py-2 mt-4 text-xl hover:bg-blue-700 bg-white text-blue-500 hover:text-white rounded-lg">
                Quote Now
              </button>
            </div>
          </div>
        </div>
        {/* our services */}
        <div className=" mt-10 py-10 ">
          <div className="text-gray-400 font-semibold text-center">
            OUR SERVICES
          </div>
          <h1 className="text-center text-3xl font-bold mt-4">
            We design UI/UX by researching, wireframing, prototyping, and
            testing to ensure a seamless user experience.
          </h1>
          <div className="text-center mt-4 text-lg">
            <div>
              Our designers adhere to established design principles and
              philosophies to create interfaces tailored to the attitudes of our
              target users.
            </div>
            <div></div>
          </div>
          <div className="mt-10 flex flex-col lg:flex-row flex-wrap justify-evenly">
            <div className="h-auto py-4 w-full lg:w-[40%] hover:shadow-all  rounded-lg bg-slate-50 shadow-all cursor-pointer hover:text-white hover:bg-blue-500 transition-all duration-700 mb-10 lg:mb-0">
              <div className="ml-10">
                <div className="font-bold text-xl">Analyze market</div>
                <p>
                  We initiate the UX design process by gathering, evaluating,
                  and defining your
                </p>
                <p>
                  {" "}
                  requirements. Additionally, we delve into understanding the
                  emotions and{" "}
                </p>
                <p> objectives of the end-user.</p>
              </div>
            </div>
            <div className="h-auto py-4 w-full lg:w-[40%] hover:shadow-all  rounded-lg bg-slate-50 shadow-all cursor-pointer hover:text-white hover:bg-blue-500 transition-all duration-700 mb-10 lg:mb-0">
              <div className="ml-10">
                <div className="font-bold text-xl">
                  Information Architecture
                </div>
                <p>
                  We ensure that every user has the ability to find exactly what
                  they're looking for.Our
                </p>
                <p>
                  team of experts meticulously organizes information to create a
                  user-friendly platform
                </p>
                <p> that's easy to navigate.</p>
              </div>
            </div>
          </div>
          {/*  */}

          <div className="mt-10 flex flex-col lg:flex-row flex-wrap justify-evenly">
            <div className="h-auto py-4 w-full lg:w-[40%] hover:shadow-all  rounded-lg bg-slate-50 shadow-all cursor-pointer hover:text-white hover:bg-blue-500 transition-all duration-700 mb-10 lg:mb-0">
              <div className="ml-10">
                <div className="font-bold text-xl">Wireframes</div>
                <p>
                  At our mobile app design firm in Noida, we specialize in
                  crafting blueprints for
                </p>
                <p>
                  your app's user interface, bringing to life the structure
                  you've envisioned.
                </p>
              </div>
            </div>
            <div className="h-auto py-4 w-full lg:w-[40%] hover:shadow-all  rounded-lg bg-slate-50 shadow-all cursor-pointer hover:text-white hover:bg-blue-500 transition-all duration-700 mb-10 lg:mb-0">
              <div className="ml-10">
                <div className="font-bold text-xl">Prototype</div>
                <p>
                  Before crafting the final design, our team of experts develops
                  a clickable prototype
                </p>
                <p>
                  of your digital product, ensuring a tangible preview and
                  smooth user experience.
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* why software developement */}
        <div className="flex flex-col lg:flex-row justify-evenly mt-10 bg-custom-back p-10">
          <div className="lg:pl-10">
            <h2 className="text-gray-400 font-semibold text-center lg:text-left">
              ABOUT UI/UX SERVICES
            </h2>

            <div className="text-center lg:text-left mt-4">
              <div className="w-full lg:w-[600px]">
                AKSH Technology stands out as the premier UI/UX design agency in
                Noida, renowned for its cutting-edge designs tailored for
                software applications, websites, and apps alike. Our designs not
                only prioritize aesthetics but also uphold the functional
                integrity of the product. With a mastery of User Experience
                design, we foster seamless interactions between your target
                audience and your digital platform. Our designs fortify your
                online presence, fostering customer loyalty and strengthening
                your brand. Leveraging advanced tools and technologies, we've
                streamlined our app UI design process for optimal efficiency and
                efficacy.
              </div>
            </div>
          </div>
          <div className="flex justify-center mb-10 lg:mb-0">
            <img className="h-72 lg:h-96" src={UI} alt="" />
          </div>
        </div>
{/*  */}
        <div className="py-10">
          <div className="text-center">
            <h2 className="text-gray-400 font-semibold text-center">
              OUR CLIENTS
            </h2>
            <div className="flex flex-wrap justify-evenly py-5">
            <img
                className="m-4 h-40"
                src="https://upload.wikimedia.org/wikipedia/en/e/e9/Banasthali_Vidyapeeth_Logo.png"
                alt="Client 1"
              />
              <img
                className="m-4"
                src={ojas}
                alt="Client 2"
              />
              <img
                className=" h-10 mt-20 "
                src={truepay}
                alt="Client 3"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UIUXDesign;
