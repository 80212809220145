
import { Route, Routes } from "react-router-dom";
import Navbar from "./Components/Navbar";



// import Footer from "./Components/Pages/Footer";  
import Footer1 from "./Components/Pages/Footer1";

import WebDev from "./Components/Pages/Sevices/WebDev";
import AppDev from "./Components/Pages/Sevices/AppDev";
import SoftDev from "./Components/Pages/Sevices/SoftDev";
import GamDev from "./Components/Pages/Sevices/GamDev";
import DigitMarket from "./Components/Pages/Sevices/DigitMarket";
import UIUXDesign from "./Components/Pages/Sevices/UIUXDesign";
import Privacy from "./Components/Policy/Privacy";
import TermCondtion from "./Components/Policy/Term&Condtion";
import Error from "./Error";
import Home from "./Components/Pages/Home";
import TeamMemberDetails from "./Components/Pages/TeamMemberDetails";
import ViewAllGallery from "./Components/Pages/ViewAllGallery";
// import Main from "./Components/Pages/Main";



function App() {
  return (
    <div className="App">
      <Navbar/>
      
        <Routes>
          <Route path="/team/:name" element={<TeamMemberDetails />} />
          <Route path="/" element={<Home />} />
          <Route path="/services/web_development" element={<WebDev/>} />
          <Route path="/services/app_development" element={<AppDev/>}/>
          <Route path="/services/software_development" element={<SoftDev/>} />
          <Route path="/services/game_development" element={<GamDev/>} />
          <Route path="/services/digital_marketing" element={<DigitMarket/>} />
          <Route path="/services/ui_ux_design" element={<UIUXDesign/>}/>
          <Route path="/privacy_policy" element={<Privacy/>}/>
          <Route path="/term&condition" element={<TermCondtion/>}/>
          <Route path="/view_all_images" element={<ViewAllGallery/>}/>
          <Route path="*" element={<Error />} />
        </Routes>
        <section id="contact" className="-mt-10">
         <Footer1 />
        </section>
    
    </div>
  );
}

export default App;
