import React, { useState, useEffect } from 'react';
import { FaBars, FaTimes } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import logo from "../Images/AKSH white.png"

const Navbar = () => {
  const [isHover, setHover] = useState(false);
  const [isShow, setShow] = useState(false);
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [isAtTop, setIsAtTop] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      setIsAtTop(window.scrollY < 50);
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className={`w-screen ${isAtTop ? 'bg-transparent' : 'bg-[#002366] bg-opacity-85'} text-white fixed top-0 z-50 transition-all duration-300`}>
      <div className='flex justify-between items-center px-4 sm:px-6 md:px-16 h-20'>
        <h1 className='font-bold text-2xl cursor-pointer'>
        <Link to="/"><img className='h-16' src={logo} alt='AKSH TECHNOLOGY' /></Link>
        
        </h1>
        <div className='lg:hidden cursor-pointer' onClick={() => setMenuOpen(!isMenuOpen)}>
          {isMenuOpen ? <FaTimes size={24} /> : <FaBars size={24} />}
        </div>
        <div className='hidden lg:flex gap-6 lg:gap-10 font-semibold'>
          <div className='cursor-pointer'><HashLink to="/#home">HOME</HashLink></div>
          <div className='cursor-pointer'><HashLink to="/#about">ABOUT</HashLink></div>
          <div
            className='cursor-pointer relative h-10'
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
          >
            <div><a href='#services'>SERVICES</a></div>
            {isHover && (
              <div className="absolute w-56 left-0 text-center -ml-10 bg-white border-gray-300 rounded-md shadow-md mt-2">
                <Link
                  to="/services/web_development"
                  className="block px-4 py-2 text-black no-underline hover:bg-[#6373FF] hover:text-white"
                >
                  Web Development
                </Link>
                <Link
                  to="/services/app_development"
                  className="block px-4 py-2 text-black no-underline hover:bg-[#6373FF] hover:text-white"
                >
                  App Development
                </Link>
                <Link
                  to="/services/software_development"
                  className="block px-4 py-2 text-black no-underline hover:bg-[#6373FF] hover:text-white"
                >
                  Software Development
                </Link>
                <Link
                  to="/services/game_development"
                  className="block px-4 py-2 text-black no-underline hover:bg-[#6373FF] hover:text-white"
                >
                  Game Development
                </Link>
                <Link
                  to="/services/digital_marketing"
                  className="block px-4 py-2 text-black no-underline hover:bg-[#6373FF] hover:text-white"
                >
                  Digital Marketing
                </Link>
                <Link
                  to="/services/ui_ux_design"
                  className="block px-4 py-2 text-black no-underline hover:bg-[#6373FF] hover:text-white"
                >
                  UI/UX Design
                </Link>
              </div>
            )}
          </div>
          <div className='cursor-pointer relative h-10'
            onMouseEnter={() => setShow(true)}
            onMouseLeave={() => setShow(false)}>
            <div>POLICY</div>
            {isShow && (
              <div className="absolute w-56 left-0 text-center -ml-10 bg-white border-gray-300 rounded-md shadow-md mt-2">
                <Link
                  to="/privacy_policy"
                  className="block px-4 py-2 text-black no-underline hover:bg-[#6373FF] hover:text-white"
                >
                  Privacy Policy
                </Link>
                <Link
                  to="/term&condition"
                  className="block px-4 py-2 text-black no-underline hover:bg-[#6373FF] hover:text-white"
                >
                  Term & Conditions
                </Link>
              </div>
            )}
          </div>
          <div className='cursor-pointer'><HashLink to="/#gallery">GALLERY</HashLink></div>
          <div className='cursor-pointer'><HashLink to="/#testimonials">TESTIMONIALS</HashLink></div>
          <div className='cursor-pointer'><HashLink to="/#team">TEAM</HashLink></div>
          <div className='cursor-pointer'><HashLink to="/#contact">CONTACT US</HashLink></div>
        </div>
      </div>
      {isMenuOpen && (
        <div className='lg:hidden bg-[#6373FF] text-white'>
          <div className='flex flex-col items-center py-4'>
            <div className='cursor-pointer'><HashLink to="/#home" onClick={() => setMenuOpen(false)}>HOME</HashLink></div>
            <div className='cursor-pointer py-2'><HashLink to="/#about" onClick={() => setMenuOpen(false)}>ABOUT</HashLink></div>
            <div className='cursor-pointer py-2' onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
              <div><HashLink to='/#services'>SERVICES</HashLink></div>
              {isHover && (
                <div className="absolute w-48 left-0 text-center ml-20 bg-white border-gray-300 rounded-md shadow-md mt-2">
                  <Link
                    to="/services/webdevelopment"
                    className="block px-4 py-2 text-black no-underline hover:bg-gray-200"
                  >
                    Web Development
                  </Link>
                  <Link
                    to="/services/appdevelopment"
                    className="block px-4 py-2 text-black no-underline hover:bg-gray-200"
                  >
                    App Development
                  </Link>
                  <Link
                    to="/services/softwaredevelopment"
                    className="block px-4 py-2 text-black no-underline hover:bg-gray-200"
                  >
                    Software Development
                  </Link>
                  <Link
                    to="/services/gamedevelopment"
                    className="block px-4 py-2 text-black no-underline hover:bg-gray-200"
                  >
                    Game Development
                  </Link>
                  <Link
                    to="/services/digitalmarketing"
                    className="block px-4 py-2 text-black no-underline hover:bg-gray-200"
                  >
                    Digital Marketing
                  </Link>
                  <Link
                    to="/services/uiuxdesign"
                    className="block px-4 py-2 text-black no-underline hover:bg-gray-200"
                  >
                    UI/UX Design
                  </Link>
                </div>
              )}
            </div>
            <div className='cursor-pointer py-2'>
              <div onMouseEnter={() => setShow(true)} onMouseLeave={() => setShow(false)}>POLICY</div>
              {isShow && (
                <div className="absolute w-56 left-0 text-center ml-20 bg-white border-gray-300 rounded-md shadow-md mt-2">
                  <Link
                    to="/privacy_policy"
                    className="block px-4 py-2 text-black no-underline hover:bg-[#6373FF] hover:text-white"
                  >
                    Privacy Policy
                  </Link>
                  <Link
                    to="/term&condition"
                    className="block px-4 py-2 text-black no-underline hover:bg-[#6373FF] hover:text-white"
                  >
                    Term & Conditions
                  </Link>
                </div>
              )}
            </div>
            <div className='cursor-pointer py-2'><HashLink to="/#gallery" onClick={() => setMenuOpen(false)}>GALLERY</HashLink></div>
            <div className='cursor-pointer py-2'><HashLink to="/#testimonials" onClick={() => setMenuOpen(false)}>TESTIMONIALS</HashLink></div>
            <div className='cursor-pointer py-2'><HashLink to="/#team" onClick={() => setMenuOpen(false)}>TEAM</HashLink></div>
            <div className='cursor-pointer py-2'><HashLink to="/#contact" onClick={() => setMenuOpen(false)}>CONTACT US</HashLink></div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Navbar;
