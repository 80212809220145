import React from "react";
import app from "../../../assets/app.svg";
import app2 from "../../../assets/app2.svg";
import ojas from "../../../assets/logo svg.svg"
import truepay from "../../../Images/truepay.png"
const AppDev = () => {
  return (
    <>
    <div className="w-full h-20 bg-[#002366]"></div>
      <div className="mt-20">
        <div className="flex flex-col lg:flex-row justify-evenly">
          <div className="px-4">
            <div className="text-gray-500 font-semibold text-center text-3xl">
              APP DEVELOPMENT
            </div>
            <div className="mt-14 w-full lg:w-[600px]">
              <div>
                Empower your business with bespoke mobile solutions crafted for
                success. From concept to deployment, we bring your app vision to
                life. Elevate your digital presence and engage your audience
                with our expert app development services.Our dedicated team
                combines cutting-edge technology with creative flair to deliver
                seamless user experiences across iOS, Android, and hybrid
                platforms. Whether you're looking to launch a brand new app or
                enhance an existing one, we're here to turn your ideas into
                reality.
              </div>
            </div>
          </div>
          <div className="flex justify-center mt-10 lg:mt-0">
            <img className="h-72 lg:h-96" src={app} alt="web images" />
          </div>
        </div>

        <div className="flex flex-col lg:flex-row justify-evenly mt-10 bg-custom-back p-10">
          <div className="flex justify-center mb-10 lg:mb-0">
            <img className="h-72 lg:h-96" src={app2} alt="" />
          </div>
          <div className="lg:pl-10">
            <h2 className="text-gray-400 font-semibold text-center lg:text-left">
              WHY APP DEVELOPMENT
            </h2>
            <div className="text-center lg:text-left font-extrabold text-3xl text-gray-400 mt-10">
              <div>Collaborate with a dedicated mobile app </div>
              <div>development company for bespoke solutions.</div>
            </div>
            <div className="text-center lg:text-left mt-4">
              <div>
              We specialize in transforming mobile apps across platforms and devices, leveraging expertise in various 
              </div>
              <div>
              technologies to create highly downloadable apps on the Play Store. With rich experience and creativity, 
              </div>
              <div>
              we deliver outstanding mobile solutions for our satisfied clients.
              </div>
              <div></div>
            </div>
            <div className="mt-6">
              <div className="mb-4">
                <div className="font-semibold flex justify-between text-lg py-2">
                  <h3>Testing</h3>
                  <h3>95%</h3>
                </div>
                <div className="h-4 w-full bg-slate-600 rounded-xl">
                  <div className="h-4 w-[95%] bg-blue-500 rounded-xl"></div>
                </div>
              </div>
              <div className="mb-4">
                <div className="font-semibold flex justify-between text-lg py-2">
                  <h3>NLP Technology</h3>
                  <h3>90%</h3>
                </div>
                <div className="h-4 w-full bg-slate-600 rounded-xl">
                  <div className="h-4 w-[90%] bg-blue-500 rounded-xl"></div>
                </div>
              </div>
              <div>
                <div className="font-semibold flex justify-between text-lg py-2">
                  <h3>Wireframes</h3>
                  <h3>92%</h3>
                </div>
                <div className="h-4 w-full bg-slate-600 rounded-xl">
                  <div className="h-4 w-[92%] bg-blue-500 rounded-xl"></div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-10 py-10">
          <div className="text-gray-400 font-semibold text-center">
            OUR SERVICES
          </div>
          <div className="mt-10 flex flex-col lg:flex-row flex-wrap justify-evenly px-4">
    <div className="h-auto py-4 w-full lg:w-[40%] hover:shadow-all flex flex-col sm:flex-row justify-around rounded-lg bg-slate-50 shadow-all cursor-pointer hover:text-white hover:bg-blue-500 transition-all duration-1000 mb-10 lg:mb-0">
        <div className="h-16 w-16 p-4 mt-6 md:ml-0 sm:mt-14 ml-36 lg:ml-6 rounded-full bg-blue-500 text-white font-extrabold text-2xl">
            01
        </div>
        <div className="mt-4 sm:mt-0 sm:ml-4">
            <div className="font-bold text-xl">Android App Development</div>
            <ul className="list-disc pl-5 mt-2">
                <li>Refine your mobile app strategy with expert consultation.</li>
                <li>Craft visually appealing and intuitive user interfaces.</li>
                <li>Integrate apps seamlessly for enhanced user experience.</li>
                <li>Ensure app longevity with reliable maintenance.</li>
            </ul>
        </div>
    </div>
    <div className="h-auto py-4 w-full lg:w-[40%] hover:shadow-all flex flex-col sm:flex-row justify-around rounded-lg bg-slate-50 shadow-all cursor-pointer hover:text-white hover:bg-blue-500 transition-all duration-1000 mb-10 lg:mb-0">
        <div className="h-16 w-16 p-4 mt-6 md:ml-0 sm:mt-14 ml-36 lg:ml-6 rounded-full bg-blue-500 text-white font-extrabold text-2xl">
            02
        </div>
        <div className="mt-4 sm:mt-0 sm:ml-4">
            <div className="font-bold text-xl">iOS App Development</div>
            <ul className="list-disc pl-5 mt-2">
                <li>Designs adapting seamlessly across devices.</li>
                <li>Ensure your app works seamlessly across devices and platforms.</li>
                <li>Minimize typing requirements for enhanced user experience.</li>
                <li>Engage users through seamless social media integration.</li>
                <li>Enhance user experience with offline functionality.</li>
            </ul>
        </div>
    </div>
    <div className="h-auto py-4 w-full lg:w-[40%] hover:shadow-all flex flex-col sm:flex-row justify-around rounded-lg bg-slate-50 shadow-all cursor-pointer hover:text-white hover:bg-blue-500 transition-all duration-1000 mb-10 sm:mt-4 lg:mb-0">
        <div className="h-16 w-16 p-4 mt-6 md:ml-0 sm:mt-14 ml-36 lg:ml-6 rounded-full bg-blue-500 text-white font-extrabold text-2xl">
            03
        </div>
        <div className="mt-4 sm:mt-0 sm:ml-4">
            <div className="font-bold text-xl">Hybrid App Development</div>
            <ul className="list-disc pl-5 mt-2">
                <li>Create sleek UI designs for optimal user experience.</li>
                <li>Optimizes efficiency for time-saving benefits.</li>
                <li>Affordable options for cost-effective solutions.</li>
                <li>Enhanced functionality even in offline mode.</li>
            </ul>
        </div>
    </div>
    <div className="h-auto sm:mt-4 py-4 w-full lg:w-[40%] hover:shadow-all flex flex-col sm:flex-row justify-around rounded-lg bg-slate-50 shadow-all cursor-pointer hover:text-white hover:bg-blue-500 transition-all duration-1000">
        <div className="h-16 w-16 p-4 mt-6 md:ml-0 sm:mt-14 ml-36 lg:ml-6 rounded-full bg-blue-500 text-white font-extrabold text-2xl">
            04
        </div>
        <div className="mt-4 sm:mt-0 sm:ml-4">
            <div className="font-bold text-xl">Blockchain Technology</div>
            <ul className="list-disc pl-5 mt-2">
                <li>Expert advice tailored to your needs.</li>
                <li>Developing decentralized apps and enterprise solutions.</li>
                <li>Create secure and reliable cryptocurrency wallets.</li>
                <li>Smart Contracts and E-wallet</li>
                <li>Implement smart contracts and e-wallet solutions efficiently.</li>
            </ul>
        </div>
    </div>
</div>

        </div>

        <div className="bg-blue-500">
          <div className="text-center text-white py-10">
            <h2 className="font-bold">MOBILE APP DEVELOPMENT</h2>
            <h1 className="text-3xl font-bold mt-4">
              Top Mobile App Development Company
            </h1>
            <div className="mt-4 text-xl">
              <p>
              Aksh Technology offers comprehensive mobile app development solutions spanning Android, iOS, and Web platforms,
              </p>
              <p>
              recognized among the top developers in Noida. We empower startups and businesses with cutting-edge
              </p>
              <p>mobile application services.</p>
            </div>
            <div>
              <button className="px-4 py-2 mt-4 text-xl hover:bg-blue-700 bg-white text-blue-500 hover:text-white rounded-lg">
                Quote Now
              </button>
            </div>
          </div>
        </div>

        <div className="py-10">
          <div className="text-center">
            <h2 className="text-gray-400 font-semibold text-center">
              OUR CLIENTS
            </h2>
            <div className="flex flex-wrap justify-evenly py-5">
              <img
                className="m-4 h-40"
                src="https://upload.wikimedia.org/wikipedia/en/e/e9/Banasthali_Vidyapeeth_Logo.png"
                alt="Client 1"
              />
              <img
                className="m-4"
                src={ojas}
                alt="Client 2"
              />
             <img
                className=" h-10 mt-20 "
                src={truepay}
                alt="Client 3"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AppDev;
