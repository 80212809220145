import React from "react";
import web from "../../../assets/web.svg";
import ojas from "../../../assets/logo svg.svg"
import truepay from "../../../Images/truepay.png"
const SoftDev = () => {
  return (
    <>
    <div className="w-full h-20 bg-[#002366]"></div>
      <div className="mt-20">
        <div className="flex flex-col lg:flex-row justify-evenly">
          <div className="px-4">
            <div className="text-gray-500 font-semibold text-center text-3xl">
              SOFTWARE DEVELOPMENT
            </div>
            <div className="mt-14 w-full lg:w-[600px]">
              <div>
                Welcome to Aksh Technology, your premier destination for the
                best software solutions . We pride ourselves on delivering
                innovative and customized software development services that
                cater to businesses of all sizes. At Aksh Technology, we blend
                cutting-edge technology with creative thinking to bring your
                ideas to life. Our team of expert developers and designers are
                dedicated to crafting software that not only meets your needs
                but also drives your business forward. Join us on the journey to
                digital excellence. Aksh Technology – where innovation meets
                perfection.
              </div>
            </div>
          </div>
          <div className="flex justify-center mt-10 lg:mt-0">
            <img className="h-72 lg:h-96" src={web} alt="web images" />
          </div>
        </div>
        {/* mobile app developement */}
        <div className="bg-blue-500">
          <div className="text-center text-white py-10">
            <h2 className="font-bold">SOFTWARE DEVELOPMENT</h2>
            <h1 className="text-3xl font-bold mt-4">
              Top Software Development Company
            </h1>
            <div className="mt-4 text-xl">
              <p>
              Our team's commitment and proficiency drive us to deliver exceptional, groundbreaking mobile apps and software solutions,
              </p>
              <p>
              streamlining diverse business operations. Our tailored solutions seamlessly integrate with your team,
              </p>
              <p>
              boosting efficiency and facilitating business growth.
              </p>
            </div>
            <div>
              <button className="px-4 py-2 mt-4 text-xl hover:bg-blue-700 bg-white text-blue-500 hover:text-white rounded-lg">
                Quote Now
              </button>
            </div>
          </div>
        </div>
        {/* our services */}
        <div className="mt-10 py-10">
          <div className="text-gray-400 font-semibold text-center">
            OUR SERVICES
          </div>
          <h1 className="text-center text-3xl font-bold mt-4">
          Unlock premium offshore software solutions tailored to your needs.
          </h1>
          <div className="text-center mt-4 text-lg">
            <div>
              At AKSH Technology, we offer a diverse range of development services customized to meet various software project requirements.
            </div>
            <div>
            From startups to large enterprises, our developers are equipped to tackle any project with expertise and efficiency.
            </div>
          </div>
          <div className="mt-10 flex flex-col lg:flex-row flex-wrap justify-evenly">
            <div className="h-auto py-4 w-full lg:w-[40%] hover:shadow-all  rounded-lg bg-slate-50 shadow-all cursor-pointer hover:text-white hover:bg-blue-500 transition-all duration-700 mb-10 lg:mb-0">
              <div className="ml-10">
                <div className="font-bold text-xl">Front-end</div>
                <div>
                  Elevate your business success with engaging, interactive, and visually captivating web front-end pages. Our front-end and UI development services prioritize usability, reliability, and exceptional UX design to help you achieve your goals effortlessly.
                </div>
              </div>
            </div>
            <div className="h-auto py-4 w-full lg:w-[40%] hover:shadow-all  rounded-lg bg-slate-50 shadow-all cursor-pointer hover:text-white hover:bg-blue-500 transition-all duration-700 mb-10 lg:mb-0">
              <div className="ml-10">
                <div className="font-bold text-xl">Back end</div>
                <div>
                Aksh Technology recognizes the critical role of the web back-end as the unseen backbone of software applications, seamlessly integrating components. That's why we offer comprehensive backend development services, leveraging expertise in multiple languages and frameworks to efficiently process and manage data.
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* why software developement */}
        <div className="flex flex-col lg:flex-row justify-evenly mt-10 bg-custom-back p-10">
          <div className="lg:pl-10">
            <h2 className="text-gray-400 mt-20 font-semibold text-center lg:text-left">
              ABOUT SOFTWARE SERVICES
            </h2>

            <div className="text-center lg:text-left mt-10">
              <div className="w-full lg:w-[600px]">
              At AKSH Technology, our dedicated software developers are committed to delivering tailored solutions and services aligned with your business requirements. With our custom software development services, we transform your digital visions into reality by crafting goal-oriented and personalized software and applications.  
              </div>
            </div>
          </div>
          <div className="flex justify-center mb-10 lg:mb-0">
            <img className="h-72 lg:h-96" src={web} alt="" />
          </div>
        </div>

       {/* Our clients */}
        <div className="py-10">
          <div className="text-center">
            <h2 className="text-gray-400 font-semibold text-center">
              OUR CLIENTS
            </h2>
            <div className="flex flex-wrap justify-evenly py-5">
            <img
                className="m-4 h-40"
                src="https://upload.wikimedia.org/wikipedia/en/e/e9/Banasthali_Vidyapeeth_Logo.png"
                alt="Client 1"
              />
              <img
                className="m-4"
                src={ojas}
                alt="Client 2"
              />
              <img
                className=" h-10 mt-20 "
                src={truepay}
                alt="Client 3"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SoftDev;
