import React from "react";

const Privacy = () => {
  return (
    <>
    <div className="h-20 bg-[#002366]"></div>
    <div className="pb-20 py-10 px-4 md:px-10 lg:px-20">
      <h1 className="text-3xl">Privacy Policy of Aksh Technology</h1>
      <p>Effective Date: May 30, 2024</p>
      
      <h2 className="text-2xl ">Introduction</h2>
      <div className="w-full lg:w-[600px]">
        Welcome to Aksh Technology. We are committed to protecting your privacy. 
        This Privacy Policy explains how we collect, use, disclose, and safeguard your information 
        when you visit our website or use our services. By accessing or using our website and services, 
        you agree to the terms of this Privacy Policy.
      </div>
      
      <h2 className="text-2xl mt-5">Information We Collect</h2>
      
      <p className="font-semibold">Personal Information</p>
      <p className="">We may collect personal information that you provide directly to us. </p>
      <p>This includes:</p>
      <div className="ml-5 md:ml-10">
        <ul className="list-disc">
          <li>Contact Information: Name, email address, phone number, and address.</li>
          <li>Account Information: Username, password, and profile information.</li>
          <li>Payment Information: Credit card details, billing address, and other financial information.</li>
          <li>Communication Data: Information from your communications with us, including customer support interactions.</li>
        </ul>
      </div>
      
      <div className="mt-4 font-semibold">Non-Personal Information</div>
      <p className="">We may also collect non-personal information automatically when you interact with our website or services. </p>
      <p>This includes:</p>
      <div className="ml-5 md:ml-10 lg:w-[600px]">
        <ul className="list-disc">
          <li>Usage Data: Pages visited, time spent on pages, links clicked, and browsing habits.</li>
          <li>Device Information: IP address, browser type, operating system, and device type.</li>
          <li>Cookies and Tracking Technologies: We use cookies, web beacons, and similar technologies to collect information and improve our services.</li>
        </ul>
      </div>
      
      <h2 className="text-2xl mt-5">How We Use Your Information</h2>
      <p className="">We use the information we collect for various purposes, including:</p>
      <div className="ml-5 md:ml-10 lg:w-[600px]">
        <ul className="list-disc">
          <li>To Provide Services: To process transactions, manage your account, and provide customer support.</li>
          <li>To Improve Services: To understand how you use our services, personalize your experience, and develop new features.</li>
          <li>To Communicate: To send you updates, promotional materials, and other information that may interest you.</li>
          <li>To Ensure Security: To detect and prevent fraud, unauthorized activities, and other security issues.</li>
          <li>To Comply with Legal Obligations: To fulfill legal requirements, respond to legal processes, and protect our rights.</li>
        </ul>
      </div>
      
      <h2 className="text-2xl mt-4">Sharing Your Information</h2>
      <p className="mt-2">We may share your information with third parties in the following circumstances:</p>
      <div className="ml-5 md:ml-10 w-full lg:w-[600px]">
        <ul className="list-disc">
          <li>Service Providers: With vendors and partners who assist us in providing services, such as payment processors, hosting providers, and marketing agencies.</li>
          <li>Legal Requirements: When required by law or to protect our rights, privacy, safety, or property, and that of our users.</li>
          <li>Business Transfers: In connection with any merger, sale of company assets, financing, or acquisition of all or a portion of our business by another company.</li>
          <li>With Your Consent: When you provide consent for us to share your information with other parties.</li>
        </ul>
      </div>
      
      <h2 className="mt-4 text-2xl">Data Security</h2>
      <div className="mt-2 w-full lg:w-[600px]">
        <p>We implement reasonable security measures to protect your information from unauthorized access, use, or disclosure. However, no security system is impenetrable, and we cannot guarantee the absolute security of your information.</p>
      </div>
      
      <h2 className="mt-4 text-2xl">Children's Privacy</h2>
      <div className="mt-2 w-full lg:w-[600px]">
        <p>Our services are not intended for children under the age of 13. We do not knowingly collect personal information from children under 13. If we become aware that we have collected personal information from children under 13, we will take steps to delete such information.</p>
      </div>
      
      <h2 className="mt-4 text-2xl">Changes to This Privacy Policy</h2>
      <div className="mt-2 w-full lg:w-[600px]">
        <p>We may update this Privacy Policy from time to time. When we do, we will revise the "Effective Date" at the top of this page. We encourage you to review this Privacy Policy periodically to stay informed about our practices.</p>
      </div>
      
      <h2 className="mt-4 text-2xl">Contact Us</h2>
      <p className="mt-2">
        If you have any questions or concerns about this Privacy Policy, please contact us at:
      </p>
      <div>
        <div className="font-semibold text-lg mt-4">Aksh Technology</div>
        <a className="underline text-blue-600" href="mailto:help@akshtechnology.com">Email: help@akshtechnology.com</a>
        <div><a href="tel:9625510038">Phone: 9625510038</a></div>
        <div>Address: T-4, A-88, Sector 4, Noida, Uttar Pradesh, 201301</div>
      </div>
    </div>
    </>
  );
};

export default Privacy;
