import React from 'react'
import error from './assets/error.svg'
const Error = () => {
  return (
   <>
    <div className='mt-28 h-screen'>
        <h1 className='text-7xl font-bold text-center text-gray-400'>PAGE NOT FOUND 404</h1>
        <img src={error} className='h-96 m-auto' alt=''/>
    </div>
   </>
  )
}

export default Error;