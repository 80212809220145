import React from "react";
import web from "../../../assets/web.svg";
import ojas from "../../../assets/logo svg.svg";
import truepay from "../../../Images/truepay.png"
const WebDev = () => {
  return (
    <>
      <div className="w-full h-20 bg-[#002366]"></div>
      <div className="mt-20">
        <div className="flex flex-col lg:flex-row justify-evenly">
          <div className="px-4">
            <div className="text-gray-500 font-semibold text-center text-3xl">
              WEB DEVELOPMENT
            </div>
            <div className="mt-14 w-full lg:w-[600px]">
              <p>
                Welcome to AKSH Technology's Web Design services! We craft
                stunning, user-friendly websites tailored to your business
                needs. Our designs are not just visually appealing but also
                intuitive, ensuring a seamless user experience. We blend
                creativity with the latest technologies to deliver custom web
                solutions that stand out. Whether you need a brand-new site or a
                redesign, our expert team is here to transform your online
                presence.
              </p>
              <p>
                Discover the power of effective web design with AKSH Technology.
                Let's create something amazing together!
              </p>
            </div>
          </div>
          <div className="flex justify-center mt-10 lg:mt-0">
            <img className="h-72 lg:h-96" src={web} alt="web images" />
          </div>
        </div>
        {/* our Services */}
        <div className="mt-10 py-10">
          <div className="text-gray-400 font-semibold text-center">
            OUR SERVICES
          </div>
          <div className="mt-10">
            <div className="flex flex-wrap justify-evenly gap-4">
              <div className="h-auto px-2 py-4 w-full md:w-[40%] hover:shadow-all flex items-center rounded-lg cursor-pointer hover:text-white hover:bg-blue-500 transition-colors duration-1000">
                <div className="h-14 w-16 p-4 rounded-full bg-blue-500 text-white font-extrabold text-2xl flex items-center justify-center">
                  01
                </div>
                <div className="ml-4">
                  <div className="font-bold text-xl">Mobile Friendly</div>
                  <p>
                    Improve your website's mobile compatibility for better
                    search engine rankings.
                  </p>
                  <p></p>
                </div>
              </div>
              <div className="h-auto px-2 py-4 w-full md:w-[40%] hover:shadow-all flex items-center rounded-lg cursor-pointer hover:text-white hover:bg-blue-500 transition-colors duration-1000">
                <div className="h-14 w-14 p-4 rounded-full bg-blue-500 text-white font-extrabold text-2xl flex items-center justify-center">
                  02
                </div>
                <div className="ml-4">
                  <div className="font-bold text-xl">Loading Time</div>
                  <p>
                    Enhance your website's loading speed to meet the
                    expectations of web visitors, who typically wait only 2 to 3
                    seconds for a page to load.
                  </p>
                  <p></p>
                </div>
              </div>
            </div>
            <div className="flex flex-wrap justify-evenly gap-4 mt-10">
              <div className="h-auto px-2 py-4 w-full md:w-[40%] hover:shadow-all flex items-center rounded-lg cursor-pointer hover:text-white hover:bg-blue-500 transition-colors duration-1000">
                <div className="h-14 w-14 p-4 rounded-full bg-blue-500 text-white font-extrabold text-2xl flex items-center justify-center">
                  03
                </div>
                <div className="ml-4">
                  <div className="font-bold text-xl">Better Security</div>
                  <p>
                    Prioritize security for your website to safeguard customer
                    data from hacking risks. Not only does this protect
                    sensitive information, but it also earns preference from
                    Google, potentially boosting your site's ranking.
                  </p>
                </div>
              </div>
              <div className="h-auto px-2 py-4 w-full md:w-[40%] hover:shadow-all flex items-center rounded-lg cursor-pointer hover:text-white hover:bg-blue-500 transition-colors duration-1000">
                <div className="h-14 w-14 p-4 rounded-full bg-blue-500 text-white font-extrabold text-2xl flex items-center justify-center">
                  04
                </div>
                <div className="ml-4">
                  <div className="font-bold text-xl">Fast Servicing</div>
                  <p>
                    Experience the fastest loading platform with our web
                    designing services in Noida.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* our Solution */}
        <div className="bg-blue-500">
          <div className="text-center text-white py-10 px-4">
            <h2 className="font-bold">WEB SOLUTION</h2>
            <h1 className="text-2xl md:text-3xl font-bold mt-4">
              Choose our web designer for all your design needs and unlock
              endless possibilities.
            </h1>
            <div className="mt-4 text-lg md:text-xl">
              <p>
                Our specialized team excels in crafting highly functional and
                lead-generating websites. We handle Our specialized team excels
                in crafting highly functional and
              </p>
              <p>
                in crafting highly functional and lead-generating websites. We
                handle everything from coding to analytics tracking, graphic
                design, content importing,
              </p>
              <p>
                contact form creation, device and browser compatibility, social
                network integration, and sitemap development.
              </p>
              <p>
                Entrust us with your website needs for a seamless online
                presence.
              </p>
            </div>
            <div>
              <button className="px-4 py-2 mt-4 text-xl hover:bg-blue-700 bg-white text-blue-500 hover:text-white rounded-lg">
                Quote Now
              </button>
            </div>
          </div>
        </div>
        {/* our Client */}
        <div className="px-4">
          <div className="text-center py-10">
            <h2 className="text-gray-400 font-semibold text-center">
              OUR CLIENTS
            </h2>
            <div className="flex flex-wrap justify-evenly py-5 gap-4">
              <img
                className="m-4 h-40"
                src="https://upload.wikimedia.org/wikipedia/en/e/e9/Banasthali_Vidyapeeth_Logo.png"
                alt="Client 1"
              />
              <img className="m-4" src={ojas} alt="Client 2" />
              <img
                className=" h-10 mt-20 "
                src={truepay}
                alt="Client 3"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WebDev;
