import React from 'react';
import { FaWordpress, FaCartArrowDown, FaChartPie } from "react-icons/fa";
import { FaCloudArrowDown } from "react-icons/fa6";
import { SlGameController } from "react-icons/sl";
import { BiSolidPlaneAlt } from "react-icons/bi";

const Services = () => {
  return (
    <div className='bg-[#6279FE] w-full pb-20 mt-10'>
      <div>
        <h1 className='font-bold text-4xl text-center py-10 text-white'>OUR SERVICES</h1>
        <div className='flex flex-wrap justify-center gap-10 px-4 md:px-10'>
          <div className='text-center'>
            <div className='h-28 w-28 rounded-full p-8 shadow-2xl bg-[#627CFE] mx-auto mb-6'><FaWordpress size={50} className='text-white'/></div>
            <h2 className='text-white text-2xl'>WordPress</h2>
            <div className='text-white mt-6'>
              <p>Unlock your website's potential with our</p>
              <p>WordPress wizardry. Let's turn your ideas into</p>
              <p>digital reality.</p>
            </div>
          </div>
          <div className='text-center'>
            <div className='h-28 w-28 rounded-full p-8 shadow-2xl bg-[#627CFE] mx-auto mb-6'><FaCartArrowDown size={50} className='text-white'/></div>
            <h2 className='text-white text-2xl'>App Development</h2>
            <div className='text-white mt-6'>
              <p>Empower your business with bespoke mobile</p>
              <p>solutions crafted for success. Elevate your digital</p>
              <p>presence and engage your audience with our</p>
              <p>expert app development services.</p>
            </div>
          </div>
          <div className='text-center'>
            <div className='h-28 w-28 rounded-full p-8 shadow-2xl bg-[#627CFE] mx-auto mb-6'><FaCloudArrowDown size={50} className='text-white'/></div>
            <h2 className='text-white text-2xl'>Software Development</h2>
            <div className='text-white mt-6'>
              <p>Crafting responsive websites that captivate and</p>
              <p>convert, tailored to your unique brand story.</p>
              <p>Elevate your online presence with our expert web</p>
              <p>development solutions.</p>
            </div>
          </div>
          <div className='text-center'>
            <div className='h-28 w-28 rounded-full p-8 shadow-2xl bg-[#627CFE] mx-auto mb-6'><SlGameController size={50} className='text-white'/></div>
            <h2 className='text-white text-2xl'>Game Development</h2>
            <div className='text-white mt-6'>
              <p>Embark on a journey through the cosmos in our</p>
              <p>immersive space exploration game.</p>
            </div>
          </div>
          <div className='text-center'>
            <div className='h-28 w-28 rounded-full p-8 shadow-2xl bg-[#627CFE] mx-auto mb-6'><BiSolidPlaneAlt size={50} className='text-white'/></div>
            <h2 className='text-white text-2xl'>UI & UX Design</h2>
            <div className='text-white mt-6'>
              <p>Elevate user experiences with our sleek UI designs</p>
              <p>and intuitive UX solutions. Transforming digital</p>
              <p>interactions into seamless journeys.</p>
            </div>
          </div>
          <div className='text-center'>
            <div className='h-28 w-28 rounded-full p-8 shadow-2xl bg-[#627CFE] mx-auto mb-6'><FaChartPie size={50} className='text-white'/></div>
            <h2 className='text-white text-2xl'>Marketing & Solution</h2>
            <div className='text-white mt-6'>
              <p>Discover our IT company's innovative solutions and</p>
              <p>expertise, tailored to empower your business. Join</p>
              <p>us in the digital revolution today!</p>
            </div>
          </div>
        </div>
      </div>   
    </div>
  )
}

export default Services;
