import React from "react";

const TermCondtion = () => {
  return (
    <>
      <div className="h-20 bg-[#002366]"></div>
      <div className="pb-20 py-10 px-4 md:px-10 lg:px-20">
        <h1 className="text-3xl">Terms and Conditions of Aksh Technology</h1>
        <p>Effective Date: May 30, 2024</p>

        <h2 className="text-2xl ">Introduction</h2>
        <div className="w-full lg:w-[600px]">
          Welcome to Aksh Technology. These Terms and Conditions govern your use
          of our website and services. By accessing or using our website and
          services, you agree to comply with and be bound by these Terms. If you
          do not agree with these Terms, please do not use our website or
          services.
        </div>

        <h2 className="text-2xl mt-5"> Use of Services</h2>

        <p className="font-semibold">Eligibility</p>
        <div className="w-full lg:w-[600px]">
          You must be at least 18 years old and capable of entering into legally
          binding agreements to use our services. By using our services, you
          represent and warrant that you meet these eligibility requirements.
        </div>

        {/* <p className="font-semibold">Account Registration</p>
      <div className="w-full lg:w-[600px]">
        To access certain features of our services, you may need to create an
        account. You agree to provide accurate, current, and complete
        information during the registration process and to update such
        information to keep it accurate, current, and complete. You are
        responsible for safeguarding your account password and for any
        activities or actions under your account. You agree to notify us
        immediately of any unauthorized use of your account.
      </div> */}

        <h2 className="text-2xl mt-5"> User Conduct</h2>
        <p>
          You agree not to use our services for any unlawful or prohibited
          activities, including but not limited to:
        </p>
        <div className="ml-5 md:ml-10">
          <ul className="list-disc">
            <li>Violating any applicable laws or regulations.</li>
            <li>Infringing the intellectual property rights of others.</li>
            <li>Distributing harmful or malicious software.</li>
            <li>
              Engaging in any activity that could harm or disrupt our services
              or the servers and networks that support our services.
            </li>
          </ul>
        </div>

        <h2 className="text-2xl mt-5"> Intellectual Property</h2>
        <div className="w-full lg:w-[600px]">
          All content and materials on our website and services, including but
          not limited to text, graphics, logos, and software, are the property
          of Aksh Technology or its licensors and are protected by copyright,
          trademark, and other intellectual property laws. You are granted a
          limited, non-exclusive, non-transferable license to access and use our
          services for personal and non-commercial purposes. Any unauthorized
          use of our content and materials is strictly prohibited.
        </div>

        <h2 className="text-2xl mt-5"> Privacy</h2>
        <div className="w-full lg:w-[600px]">
          Your use of our services is also governed by our Privacy Policy, which
          is incorporated into these Terms by reference. Please review our
          Privacy Policy to understand how we collect, use, and protect your
          personal information.
        </div>

        {/* <h2 className="text-2xl mt-5">5. Payment and Subscriptions</h2>
      <div className="w-full lg:w-[600px]">
        Certain features of our services may be available through paid
        subscriptions. By subscribing to our services, you agree to pay the
        applicable subscription fees and any taxes. Subscription fees are
        billed in advance and are non-refundable. We reserve the right to
        change our subscription fees at any time, and we will notify you of
        any changes in advance.
      </div> */}

        <h2 className="text-2xl mt-5"> Termination</h2>
        <div className="w-full lg:w-[600px]">
          We reserve the right to terminate or suspend your account and access
          to our services at our sole discretion, without prior notice, for
          conduct that we believe violates these Terms or is otherwise harmful
          to our interests or those of other users. Upon termination, your right
          to use our services will immediately cease.
        </div>

        <h2 className="text-2xl mt-5">
          Disclaimers and Limitation of Liability
        </h2>
        <p className="font-semibold">Disclaimers</p>
        <div className="w-full lg:w-[600px]">
          Our services are provided "as is" and "as available" without
          warranties of any kind, either express or implied, including but not
          limited to warranties of merchantability, fitness for a particular
          purpose, and non-infringement. We do not warrant that our services
          will be uninterrupted, error-free, or free of viruses or other harmful
          components.
        </div>

        <p className="font-semibold mt-2">Limitation of Liability</p>
        <div className="w-full lg:w-[600px]">
          To the fullest extent permitted by law, Aksh Technology and its
          affiliates, officers, directors, employees, and agents will not be
          liable for any indirect, incidental, special, consequential, or
          punitive damages, or any loss of profits or revenues, whether incurred
          directly or indirectly, or any loss of data, use, goodwill, or other
          intangible losses, resulting from :-
          <div> (i) your use or inability to use our services.</div>
          <div>
            (ii) any unauthorized access to or use of our servers and/or any
            personal information stored therein
          </div>
          <div>
         
            (iii) any interruption or cessation of transmission to or from our
            services
          </div>
          <div>
            (iv) any bugs, viruses, or the like that may be transmitted to or
            through our services by any third party
          </div>
          <div>
            (v) any errors or omissions in any content or for any loss or damage
            incurred as a result of the use of any content posted, emailed,
            transmitted, or otherwise made available through our services.
          </div>
        </div>

        <h2 className="text-2xl mt-5"> Governing Law</h2>
        <div className="w-full lg:w-[600px]">
          These Terms shall be governed by and construed in accordance with the
          laws of the State of Texas, without regard to its conflict of law
          principles. Any disputes arising out of or relating to these Terms or
          our services shall be resolved through binding arbitration in
          accordance with the rules of the American Arbitration Association. The
          arbitration shall be conducted in Tech City, Texas, and the
          arbitration award may be entered in any court having jurisdiction. You
          agree to waive any right to a jury trial or to participate in a class
          action.
        </div>

        <h2 className="text-2xl mt-5"> Changes to These Terms</h2>
        <div className="w-full lg:w-[600px]">
          We may update these Terms from time to time. When we do, we will
          revise the "Effective Date" at the top of this page. We encourage you
          to review these Terms periodically to stay informed about our terms of
          use.
        </div>

        <h2 className="text-2xl mt-5">Contact Us</h2>
        <p>
          If you have any questions or concerns about these Terms, please
          contact us at:
        </p>
        <div>
          <div className="font-semibold text-lg">Aksh Technology</div>
          <a
            className="underline text-blue-600"
            href="mailto:help@akshtechnology.com"
          >
            Email: help@akshtechnology.com
          </a>
          <div>
            <a href="tel:9625510038">Phone: 9625510038</a>
          </div>
          <div>Address:T-4, A-88, Sector 4, Noida, Uttar Pradesh, 201301</div>
        </div>
      </div>
    </>
  );
};

export default TermCondtion;
