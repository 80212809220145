import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const data = [
  { image:"https://i.pinimg.com/280x280_RS/79/dd/11/79dd11a9452a92a1accceec38a45e16a.jpg", title: 'Working with Aksh Technology was a breeze! They delivered our project on time and exceeded our expectations with their expertise and professionalism', author: '-Aarti Khandelwala'},
  { image: 'https://i.pinimg.com/280x280_RS/79/dd/11/79dd11a9452a92a1accceec38a45e16a.jpg', title: 'Thank you, Aksh Technology, for your outstanding work and dedication to excellence. You have not only earned our trust but also our highest recommendation', author: '- Kapila Singh' },
  { image: 'https://i.pinimg.com/280x280_RS/79/dd/11/79dd11a9452a92a1accceec38a45e16a.jpg', title: `'Working with Aksh Technology was an absolute pleasure. They not only delivered our project on time but also exceeded our expectations. Their team went above and beyond to ensure our project was completed on schedule and within budget. We're thrilled with the results!`, author: '- Pranjal Sharma' },

];

const OurClient = () => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

  return (
    <>
  <div className="text-center py-1">
    <h1 className="font-bold text-2xl sm:text-3xl md:text-4xl">WHAT OUR CLIENTS SAY</h1>
    <div className="h-1 bg-blue-400 w-16 mt-4 mx-auto"></div>
</div>
      <Carousel swipeable={true} responsive={responsive}  showDots={true}  infinite={true} autoPlay={true} autoPlaySpeed={3000}  keyBoardControl={true} removeArrowOnDeviceType={['mobile', 'tablet']}>
    
        {data.map((item)=> (
          <div className="p-10 items-center bg-[#6279FE] gap-10  h-96 text-white">
          <img className='w-20 h-20 rounded-full m-auto' src={item.image} alt=''/>
          <div><p className="text-wrap font-medium  text-center">{item.title}</p></div>
          <div><p className="text-end font-bold ">{item.author}</p></div>
          </div>
        ))}
   
      </Carousel>
      </>
  );
};

export default OurClient ;
